import { Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import utils from "../../../utils/utils";

const useStyles = makeStyles({
  table: {
    width: "100%",
    maxHeight: 600,
  },
});

export function RecordTablePubParent({
  publicationParentList,
  handleCurrentRecordIdx,
  resetIndex,
  disableActions,
}) {
  const classes = useStyles();
  const firstRecordId = React.useRef(publicationParentList[0].listPapers[0].id);
  const [selectedId, setSelectedId] = useState(
    publicationParentList[0].listPapers[0].id
  );
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    setSelectedId(firstRecordId.current);
    setTextValue("");
  }, [resetIndex]);

  useEffect(() => {
    firstRecordId.current = publicationParentList[0].listPapers[0].id;
  }, [publicationParentList]);

  const handleClick = (e, row) => {
    if (disableActions && row.id !== selectedId) {
      swal({
        title: "Unsaved Changes",
        text: "Do you want to leave without saving?.",
        icon: "warning",
        buttons: ["Cancel", "Yes, leave"],
      }).then((ok) => {
        console.log("ok: " + ok);
        if (ok) {
          setSelectedId(row.id);
          handleCurrentRecordIdx(row.id);
        } else {
          return false;
        }
      });
    } else {
      setSelectedId(row.id);
      handleCurrentRecordIdx(row.id);
    }
  };

  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  const filterItems = (item) => {
    return textValue === ""
      ? item
      : item.name.toLowerCase().match(textValue.toLowerCase());
    // item.name.toLowerCase().startsWith(textValue.toLowerCase());
  };

  if (publicationParentList) {
    var tableItems = createTableItems(publicationParentList);
  }

  return (
    <TableContainer className={classes.table}>
      {/* {"Select Paper"} */}
      <Table
        stickyHeader
        className={classes.table}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell padding="none">
              <Input
                placeholder="Search Paper:"
                type="text"
                value={textValue}
                onChange={handleChange}
              ></Input>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableItems && tableItems}</TableBody>
      </Table>
    </TableContainer>
  );

  function createTableItems(publicationParentList) {
    let tableItems;

    if (publicationParentList.length > 0) {
      var recordNameList = [];
      publicationParentList.forEach((pp) => {
        pp.listPapers.forEach((paper) => {
          recordNameList.push({
            id: paper.id,
            name: utils.getPaperNameBasedOnPP(pp, paper),
          });
        });
      });
    }

    if (recordNameList && publicationParentList.length > 0) {
      tableItems = recordNameList.filter(filterItems).map((row, idx) => {
        return (
          <TableRow
            selected={row.id === selectedId}
            key={idx}
            onClick={(e) => handleClick(e, row)}
            disabled={true}
          >
            <TableCell scope="row" disabled={true}>
              {row.name}
            </TableCell>
          </TableRow>
        );
      });
    } else {
      tableItems = [];
      tableItems.push(
        <TableRow key={0}>
          <TableCell component="th" scope="row">
            No table items
          </TableCell>
        </TableRow>
      );
    }

    return tableItems;
  }
}

export default RecordTablePubParent;
