import { Box, Checkbox, Grid, Typography } from "@material-ui/core";
import {
  IcdContentList,
  PublicationContent,
} from "../../../../model/publicationdata/PublicationData";
import { PublicationContentComponent } from "./PublicationContentComponent";

import React from "react";
import {
  getConfStateByWorkflowState,
  setConfStateByWorkflowState,
} from "../../../../utils/validation/confirmationByStateUtils";
import { UserConfirmationState } from "../../../../model/annotation/UserConfirmationState";
import { overWriteCheckboxDisable } from "../../../../utils/validation/ValidationWorkflowStateUtils";

interface IcdListProps {
  icdEntries: IcdContentList;
  currentWorkflowState: string;
  componentTitle: string;
  type: string; // ICD10 or ICD11
  updateIcdContentInPublicationData: (
    icdEntries: IcdContentList,
    type: string
  ) => void;
}

export default function IcdListComponent({
  icdEntries,
  currentWorkflowState,
  componentTitle,
  type,
  updateIcdContentInPublicationData,
}: IcdListProps): any {
  const _checked =
    icdEntries !== null
      ? getConfStateByWorkflowState(
          icdEntries.userConfirmationByState,
          currentWorkflowState
        ) === UserConfirmationState.ACCEPTED.name
      : false;

  const [checked, setChecked] = React.useState(_checked);
  const [disableTextFields, setDisableTextFields] = React.useState(_checked);
  const [disableCheckbox, setDisableCheckbox] = React.useState(false);

  if (icdEntries === null) {
    return (
      <div>
        <Box display="flex" flexDirection="row" mt={1.5}>
          <Typography variant="h6">{componentTitle}</Typography>
        </Box>
        <Box ml={2}>
          <Typography>No ICD entries</Typography>
        </Box>
      </div>
    );
  }

  const handleChangeCheckBox = (event: any) => {
    setChecked(event.target.checked);
    setDisableTextFields(event.target.checked);

    let check;

    if (event.target.checked) {
      check = UserConfirmationState.ACCEPTED.name;
    } else {
      check = UserConfirmationState.DEFAULT.name;
    }

    const newIcdEntries = icdEntries;
    setConfStateByWorkflowState(
      newIcdEntries.userConfirmationByState,
      currentWorkflowState,
      check
    );
    updateIcdContentInPublicationData(newIcdEntries, type);
  };

  const handleDisableCheckbox = (disable: boolean) => {
    setDisableCheckbox(disable);
  };

  const onSaveItem = (
    icdContent: PublicationContent,
    contentTypeIdx: string
  ) => {
    const idx: number = +contentTypeIdx;
    const newIcdEntries = icdEntries;
    newIcdEntries.icdList[idx] = icdContent;

    let userConfirmation: any;
    if (icdContent.content !== icdContent.contentOriginal) {
      userConfirmation = UserConfirmationState.CHANGED.name;
    } else {
      userConfirmation = UserConfirmationState.DEFAULT.name;
    }

    setConfStateByWorkflowState(
      newIcdEntries.userConfirmationByState,
      currentWorkflowState,
      userConfirmation
    );

    updateIcdContentInPublicationData(newIcdEntries, type);
  };

  const confirmationStateInChangeMode =
    getConfStateByWorkflowState(
      icdEntries.userConfirmationByState,
      currentWorkflowState
    ) === UserConfirmationState.CHANGED.name;

  const overwriteCheckboxDisable =
    overWriteCheckboxDisable(currentWorkflowState);
  const disableCheckboxLocally =
    (disableCheckbox ||
      (isAtleastOneVariableDifferent(icdEntries) && !checked) ||
      confirmationStateInChangeMode) &&
    !overwriteCheckboxDisable;

  const { icdList } = icdEntries;
  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        marginLeft={2}
        mb={1}
      >
        <Box flexDirection="row" mb={0.5} mt={1.5}>
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{ width: 300, maxWidth: 700 }}
          >
            <Grid item xs={7}>
              <Typography variant="h6">{componentTitle}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Box ml={2}>Validate</Box>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={checked}
                onChange={handleChangeCheckBox}
                disabled={disableCheckboxLocally}
              />
            </Grid>
          </Grid>
        </Box>
        <Box flexDirection="row" mb={0.5} mt={0.5} ml={2}>
          {icdList &&
            icdList.map((c, idx) => {
              return (
                // temporal solution, idx is not good practice
                <div key={idx}>
                  <PublicationContentComponent
                    content={c}
                    contentType={idx + ""}
                    onSaveItem={onSaveItem}
                    handleDisableCheckbox={handleDisableCheckbox}
                    disableTextField={disableTextFields}
                  />
                </div>
              );
            })}
        </Box>
      </Box>
    </div>
  );
}

const isAtleastOneVariableDifferent = (icdEntries: IcdContentList) => {
  icdEntries.icdList.forEach((icdEntry) => {
    if (icdEntry !== null && icdEntry.content !== icdEntry.contentOriginal) {
      return true;
    }
  });

  return false;
};
