import { Box, Grid, Typography } from "@material-ui/core";
import ForwardIcon from "@material-ui/icons/Forward";
import React from "react";
import {
  NavLink,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { WorkflowStates } from "../model/WorkflowStates";
import { BUTTON_NAV_NAMES, TOP_NAV_NAMES } from "./navbarNames";
import { ROUTES } from "./routes";
import PapersTableVirtual from "./subcomponents/tables/PapersTableVirtual";
import SWTMenu from "./SWTMenu";
import FinalValidationReleaseView from "./views/FinalValidationReleaseView";
import ImportView from "./views/ImportView";
import VariableValidationView from "./views/VariableValidationView";
import ViewDummyTable from "./views/ViewDummyTable";
import ViewForm from "./views/ViewForm";
import ViewTable from "./views/ViewTable";

export const SourceSelection = () => {

  return (
    <div>
      <Box mr={25} display="flex" flexDirection="row" justifyContent="center">
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.PAPER_IMPORT}
          to={`${ROUTES.PAPER_IMPORT}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.REFERENCE_LOOKUP}
          to={`${ROUTES.REFERENCE_LOOKUP}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
      </Box>
      <Routes>
        <Route
          path={ROUTES.SOURCE_SELECTION}
          element={<Navigate to={`${ROUTES.PAPER_IMPORT}`} replace/>}
        />
        <Route path={`${ROUTES.PAPER_IMPORT}`} element={<Imported />} />
        <Route path={`${ROUTES.REFERENCE_LOOKUP}`} element={<ReferenceLookup />} />
        <Route path='*' element={<NotFoundPage />} />

      </Routes>
    </div>
  );
};

export const IncomingPapers = () => {

  return (
    <div>
      <Box mr={25} display="flex" flexDirection="row" justifyContent="center">
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.AUTO_PAPER_SELECTION_REVIEW}
          to={`${ROUTES.AUTO_PAPER_SELECTION_REVIEW}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.PAPER_SELECTION}
          to={`${ROUTES.PAPER_SELECTION}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.PAPER_SELECTION_REVIEW}
          to={`${ROUTES.PAPER_SELECTION_REVIEW}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.PAPER_UPLOAD}
          to={`${ROUTES.PAPER_UPLOAD}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.NOT_FOUND_PAPERS}
          to={`${ROUTES.NOT_FOUND_PAPERS}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
      </Box>
      <Routes>
        <Route path={ROUTES.INCOMING_PAPERS} element={<Navigate to={`${ROUTES.PAPER_SELECTION}`} replace />}/>
        <Route path={`${ROUTES.AUTO_PAPER_SELECTION_REVIEW}`} element={<AutoPaperSelectionReview />} />
        <Route path={`${ROUTES.PAPER_SELECTION}`} element={<SelectionAssessment />} />
        <Route path={`${ROUTES.PAPER_SELECTION_REVIEW}`} element={<SelectionAssessmentReview />} />
        <Route path={`${ROUTES.PAPER_UPLOAD}`} element={<PaperUpload />} />
        <Route path={`${ROUTES.NOT_FOUND_PAPERS}`} element={<NotFoundPapers />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export const EligibilityAssessment = () => {

  return (
    <div>
      <Box mr={25} display="flex" flexDirection="row" justifyContent="center">
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.DESIGN_PRE_ANNOTATION}
          to={`${ROUTES.DESIGN_PRE_ANNOTATION}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.ELIGIBILITY_ASSESSMENT_MAPPING}
          to={`${ROUTES.ELIGIBILITY_ASSESSMENT_MAPPING}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.MANUAL_ASSESSMENT}
          to={`${ROUTES.MANUAL_ASSESSMENT}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
      </Box>
      <Routes>
        <Route
          path={ROUTES.ELIGIBILITY_ASSESSMENT}
          element={<Navigate to={`${ROUTES.MANUAL_ASSESSMENT}`} replace />}
        />
        <Route path={`${ROUTES.DESIGN_PRE_ANNOTATION}`} element={<DesignAuto />} />
        <Route path={`${ROUTES.ELIGIBILITY_ASSESSMENT_MAPPING}`} element={<EligibilityAssessmentMapping />} />
        <Route path={`${ROUTES.MANUAL_ASSESSMENT}`} element={<ManualAssessment />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export const Analysis = () => {

  return (
    <div>
      <Box mr={25} display="flex" flexDirection="row" justifyContent="center">
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.PRE_ANNOTATION}
          to={`${ROUTES.PRE_ANNOTATION}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.ANNOTATION}
          to={`${ROUTES.ANNOTATION}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.REVIEW}
          to={`${ROUTES.REVIEW}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
      </Box>
      <Routes>
        <Route
          path={ROUTES.PAPER_ANALYSIS}
          element={<Navigate to={`${ROUTES.ANNOTATION}`} replace/>}
        />
        <Route path={`${ROUTES.PRE_ANNOTATION}`} element={<AnalysisAuto />} />
        <Route path={`${ROUTES.ANNOTATION}`} element={<AnalysisAnnotation />} />
        <Route path={`${ROUTES.REVIEW}`} element={<AnalysisAnnotationReview />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export const Extraction = () => {

  return (
    <div>
      <Box mr={25} display="flex" flexDirection="row" justifyContent="center">
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.AUTO_EXTRACTION}
          to={`${ROUTES.AUTO_EXTRACTION}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.EXTRACTION_VALIDATION_VARIABLES}
          to={`${ROUTES.EXTRACTION_VALIDATION_VARIABLES}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.EXTRACTION_VALIDATION_DATA}
          to={`${ROUTES.EXTRACTION_VALIDATION_DATA}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
      </Box>
      <Routes>
        <Route
          path={ROUTES.EXTRACTION}
          element={<Navigate to={`${ROUTES.EXTRACTION_VALIDATION_VARIABLES}`} replace/>}
        />
        <Route
          path={`${ROUTES.AUTO_EXTRACTION}`}
          element={<ViewDummyTable state={WorkflowStates.AUTO_EXTRACTION.state} />}
        />
        <Route
          path={`${ROUTES.EXTRACTION_VALIDATION_VARIABLES}`}
          element={<VariableValidationView state={WorkflowStates.EXTRACTION_VALIDATION_VARIABLES.state} />}
        />
        <Route path={`${ROUTES.EXTRACTION_VALIDATION_DATA}`} element={<ExtractionDataValidation />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export const Release = () => {
  
  return (
    <div>
      <Box mr={25} display="flex" flexDirection="row" justifyContent="center">
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.AUTO_RELEASE_VALIDATION}
          to={`${ROUTES.AUTO_RELEASE_VALIDATION}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.RELEASE_VALIDATION}
          to={`${ROUTES.RELEASE_VALIDATION}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.RELEASE_VALIDATION_REVIEW}
          to={`${ROUTES.RELEASE_VALIDATION_REVIEW}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
        <ForwardIcon fontSize="small" style={arrowStyleLevel2} />
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.RELEASED}
          to={`${ROUTES.RELEASED}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
      </Box>
      <Routes>
        <Route
          path={ROUTES.RELEASE}
          element={<Navigate to={`${ROUTES.RELEASE_VALIDATION}`} replace/>}
        />
        <Route
          path={`${ROUTES.AUTO_RELEASE_VALIDATION}`}
          element={<ViewDummyTable state={WorkflowStates.AUTO_RELEASE_VALIDATION.state} />}
        />
        <Route
          path={`${ROUTES.RELEASE_VALIDATION}`}
          element={<FinalValidationRelease state={WorkflowStates.RELEASE_VALIDATION.state} />}
        />
        <Route 
          path={`${ROUTES.RELEASE_VALIDATION_REVIEW}`}
          element={<FinalValidationRelease state={WorkflowStates.RELEASE_VALIDATION_REVIEW.state}/>}
        />
        <Route 
          path={`${ROUTES.RELEASED}`}
          element={<ReleasedPublications state={WorkflowStates.RELEASED.state} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export const Miscellaneous = () => {

  return (
    <div>
      <Box mr={25} display="flex" flexDirection="row" justifyContent="center">
        <ButtonNavLink
          name={BUTTON_NAV_NAMES.TO_BE_DISCUSSED}
          to={`${ROUTES.TO_BE_DISCUSSED}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.LONG_TERM_TO_BE_DISCUSSED}
          to={`${ROUTES.LONG_TERM_TO_BE_DISCUSSED}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>

        <ButtonNavLink
          name={BUTTON_NAV_NAMES.ALL_PAPERS}
          to={`${ROUTES.ALL_PAPERS}`}
          style={styleNavLevel2}
          activeStyle={styleNavLevel2_focus}
        ></ButtonNavLink>
      </Box>
      <Routes>
        <Route
          path={ROUTES.MISCELLANEOUS}
          element={<Navigate to={`${ROUTES.TO_BE_DISCUSSED}`} replace />}
        />
        <Route path={`${ROUTES.TO_BE_DISCUSSED}`} element={<ToBeDiscussed />} />
        <Route path={`${ROUTES.LONG_TERM_TO_BE_DISCUSSED}`} element={<LongTermToBeDiscussed />} />
        <Route path={`${ROUTES.ALL_PAPERS}`} element={<AllPapers />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

const Imported = () => {
  return <ImportView />;
};

const ReferenceLookup = () => {
  return <ViewTable state={WorkflowStates.AUTOMATED_REFERENCE_LOOKUP.state} />;
};

const AutoPaperSelectionReview = () => {
  return <ViewForm state={WorkflowStates.AUTO_PAPER_SELECTION_REVIEW.state} />;
};

const SelectionAssessment = () => {
  return <ViewForm state={WorkflowStates.SELECTION_ASSESSMENT.state} />;
};

const SelectionAssessmentReview = () => {
  return <ViewForm state={WorkflowStates.SELECTION_ASSESSMENT_REVIEW.state} />;
};

const PaperUpload = () => {
  return <ViewForm state={WorkflowStates.INCOMING.state} />;
};

const NotFoundPapers = () => {
  return <ViewForm state={WorkflowStates.INCOMING_NOT_FOUND.state} />;
};

const DesignAuto = () => {
  return (
    <ViewTable
      state={WorkflowStates.ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN.state}
    />
  );
};

const ManualAssessment = () => {
  return (
    <ViewForm state={WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state} />
  );
};

const AnalysisAuto = () => {
  return <ViewTable state={WorkflowStates.ANALYSIS_PREANNOTATION.state} />;
};

const AnalysisAnnotation = () => {
  return <ViewForm state={WorkflowStates.ANALYSIS.state} />;
};

const AnalysisAnnotationReview = () => {
  return <ViewForm state={WorkflowStates.ANALYSIS_REVIEW.state} />;
};

const EligibilityAssessmentMapping = () => {
  return <ViewForm state={WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING.state} />;
};

const ExtractionDataValidation = () => {
  return <ViewForm state={WorkflowStates.EXTRACTION_VALIDATION_DATA.state} />;
};

const FinalValidationRelease = ({ state }) => {
  return <FinalValidationReleaseView state={state} />;
};

const ReleasedPublications = () => {
  return <ViewForm state={WorkflowStates.RELEASED.state} />;
};

const ToBeDiscussed = () => {
  return <ViewForm state={WorkflowStates.TO_BE_DISCUSSED.state} />;
};

const LongTermToBeDiscussed = () => {
  return <ViewForm state={WorkflowStates.LONG_TERM_TO_BE_DISCUSSED.state} />;
};

const AllPapers = () => {
  return (
    <div>
      <br />
      <PapersTableVirtual />
    </div>
  );
};

export const ButtonNavLink = ({ name, to, activeStyle, style }) => {
  return (
  <NavLink  to={to} style={({ isActive }) => isActive ? activeStyle : style}>
      <Typography variant="body2" align="center">
        {name}
      </Typography>
  </NavLink>
  );
};

export const NavBar = () => {
  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row">
        <Grid item lg={11} md>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <ButtonNavLink
              name={TOP_NAV_NAMES.SOURCE_SELECTION}
              to='/source-selection/paper-import'
              style={styleNavLevel1}
              activeStyle={styleNavLevel1_focus}
            ></ButtonNavLink>
            <ForwardIcon fontSize="medium" style={arrowStyleLevel1} />
            <ButtonNavLink
              name={TOP_NAV_NAMES.INCOMING_PAPERS}
              to='/incoming-papers/paper-selection'
              style={styleNavLevel1}
              activeStyle={styleNavLevel1_focus}
            ></ButtonNavLink>
            <ForwardIcon fontSize="medium" style={arrowStyleLevel1} />
            <ButtonNavLink
              name={TOP_NAV_NAMES.ELIGIBILITY_ASSESSMENT}
              to='/eligibility-assessment/manual-assessment'
              style={styleNavLevel1}
              activeStyle={styleNavLevel1_focus}
            ></ButtonNavLink>

            <ForwardIcon fontSize="medium" style={arrowStyleLevel1} />
            <ButtonNavLink
              name={TOP_NAV_NAMES.PAPER_ANALYSIS}
              to='/paper-analysis/annotation'
              style={styleNavLevel1}
              activeStyle={styleNavLevel1_focus}
            ></ButtonNavLink>
            <ForwardIcon fontSize="medium" style={arrowStyleLevel1} />
            <ButtonNavLink
              name={TOP_NAV_NAMES.EXTRACTION}
              to='/extraction/validation-variables'
              style={styleNavLevel1}
              activeStyle={styleNavLevel1_focus}
            ></ButtonNavLink>
            <ForwardIcon fontSize="medium" style={arrowStyleLevel1} />
            <ButtonNavLink
              name={TOP_NAV_NAMES.RELEASE}
              to='/release/release-validation'
              style={styleNavLevel1}
              activeStyle={styleNavLevel1_focus}
            ></ButtonNavLink>

            <ButtonNavLink
              name={TOP_NAV_NAMES.MISCELLANEOUS}
              to='/miscellaneous/to-be-discussed'
              style={styleMiscellaneous}
              activeStyle={styleMiscellaneous_focus}
            ></ButtonNavLink>
          </Box>
        </Grid>
        <Grid item lg={1} md>
          <Box display="flex" alignItems="flex-end" flexDirection="column">
            <SWTMenu />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const NotFoundPage = () => {
  return (
    <Box
      display="flex"
      alignContent="flex-start"
      flexDirection="column"
      mx={70}
      my={30}
    >
      <Typography variant="h2" color="secondary">
        Page Not Found
      </Typography>
    </Box>
  );
};

export const NotImplementedYet = () => {
  return (
    <Box
      display="flex"
      alignContent="flex-start"
      flexDirection="column"
      mx={70}
      my={30}
    >
      <Typography variant="h2" color="secondary">
        Not implemented yet
      </Typography>
    </Box>
  );
};

export const styleNavLevel1 = {
  textDecoration: "none",
  color: "white",
  textTransform: "none",
  borderRadius: "20px",
  background: "linear-gradient(to right, #5f839e, #6f93ae)",
  marginLeft: 6,
  marginRight: 6,
  marginTop: 6,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  paddingBottom: 3,
};

export const styleNavLevel1_focus = {
  textDecoration: "none",
  color: "white",
  textTransform: "none",
  borderRadius: "20px",
  background: "linear-gradient(to right, #253f5b, #4f738e)",
  marginLeft: 6,
  marginRight: 6,
  marginTop: 6,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  paddingBottom: 3,
};

export const styleNavLevel2 = {
  textDecoration: "none",
  color: "white",
  textTransform: "none",
  borderRadius: "20px",
  background: "linear-gradient(to right, #F58219, #ffac26)",
  marginLeft: 6,
  marginRight: 6,
  marginTop: 8,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  paddingBottom: 3,
};

export const styleNavLevel2_focus = {
  textDecoration: "none",
  color: "white",
  textTransform: "none",
  borderRadius: "20px",
  background: "linear-gradient(to right, #F13811, #F58219 )",
  marginLeft: 6,
  marginRight: 6,
  marginTop: 8,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  paddingBottom: 3,
};

const styleMiscellaneous = {
  textDecoration: "none",
  color: "white",
  textTransform: "none",
  borderRadius: "20px",
  background: "linear-gradient(to right, #5f839e, #6f93ae)",
  marginLeft: 60,
  marginRight: 6,
  marginTop: 6,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  paddingBottom: 3,
};

const styleMiscellaneous_focus = {
  color: "white",
  textTransform: "none",
  borderRadius: "20px",
  background: "linear-gradient(to right, #253f5b, #4f738e)",
  marginLeft: 60,
  marginRight: 6,
  marginTop: 6,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  paddingBottom: 3,
};

const arrowStyleLevel2 = {
  marginTop: 8,
};

const arrowStyleLevel1 = {
  marginTop: 6,
};
