import {
  Box,
  Button,
  PropTypes,
  TextField,
  // TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import api from "../../api";
import { buttonColor } from "../subcomponents/buttons/buttonProperties";
import Spinner from "../subcomponents/notification/Spinner";
import ViewFormLayout from "./layouts/ViewFormLayout";
import { AreaTopicPaperCreation } from "../../model/base/AreaTopicPaperCreation";
import swal from "sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { UsersContext } from "../Context";

/**
 * In this view the user can create an area with topic and papers
 */
export default function AreaTopicCreation() {
  const [currentAreaName, setCurrentAreaName] = React.useState("");
  const [currentTopicName, setCurrentTopicName] = React.useState("");
  const [currentPaperTitle, setCurrentPaperTitle] = React.useState("");
  const [currentPaperYear, setCurrentPaperYear] = React.useState("");
  const [currentPubmedId, setCurrentPubmedId] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const context: any = React.useContext(UsersContext);
  const currentUser: string | null = context.currentUser;

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = (response: any) => {
    console.log("status: " + response.status);
    const title = "Import Pubmed Search Review";

    if (response.status === 200) {
      const pubmedSearchData = response.data;

      let msg =
        "Topic: " +
        pubmedSearchData.topicName +
        "\n Area: " +
        pubmedSearchData.areaName;

      if (pubmedSearchData.message != null) {
        msg +=
          "\n\nPubmed search review uploaded successful, please be aware of the following:\n\n" +
          pubmedSearchData.message;
      }
      swal(title, msg, "success", {
        closeOnClickOutside: false,
      }).then((ok) => {
        if (ok) {
          setCurrentAreaName("");
          setCurrentTopicName("");
          setCurrentPaperTitle("");
          setCurrentPubmedId("");
          setCurrentPaperYear("");
          window.location.reload();
        }
      });
    } else {
      // response is a text: 201:created, 208: already reported, 204: no content
      let msg = title;
      if (response.data.message != null) {
        msg = response.data.message;
      }
      swal(msg, response.data, "warning", { closeOnClickOutside: false });
    }
    setIsProcessing(false);
  };

  const handleError = (e: any) => {
    // const title = "Import Review";
    console.log("error: " + JSON.stringify(e.response));
    setIsProcessing(false);
    // let msg = "No data from server";
    if (e.response !== undefined && e.response.data !== null) {
      let msg = e.response.data.toString();

      swal(msg, e.response.data, "warning", {
        closeOnClickOutside: false,
      }).then((ok) => {
        if (ok) {
        }
      });
    }
  };

  const onCreateAreaAndTopicWithPaper = (e: any) => {
    if (currentUser !== "martin" && currentUser !== "alberto") {
      swal(
        "Not allowed",
        'Creating topics is only allowed by the user "martin"',
        "warning",
        {
          closeOnClickOutside: false,
        }
      );
      return;
    }
    setIsProcessing(true);
    const areaTopicPaper: AreaTopicPaperCreation = {
      areaName: currentAreaName,
      topicName: currentTopicName,
      paperTitle: currentPaperTitle,
      paperYear: currentPaperYear,
      pubmedId: currentPubmedId,
    };

    api
      .createAreaAndTopic(areaTopicPaper)
      .then((response) => {
        handleSuccess(response);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const checkReady = () => {
    if (
      currentTopicName &&
      currentAreaName &&
      currentPaperTitle &&
      currentPaperYear &&
      isNumber(currentPaperYear)
    )
      return true;
    return false;
  };

  const isNumber = (value: string | number): boolean => {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  };

  const handleChange = (field: any) => (e: any) => {
    if (field === "Area Name") {
      setCurrentAreaName(e.target.value);
    } else if (field === "Topic Name") {
      setCurrentTopicName(e.target.value);
    } else if (field === "Paper title") {
      setCurrentPaperTitle(e.target.value);
    } else if (field === "Paper year") {
      setCurrentPaperYear(e.target.value);
    } else if (field === "Pubmed ID") {
      setCurrentPubmedId(e.target.value);
    }
  };

  const importPubmedFileButton = CreateButton(
    onCreateAreaAndTopicWithPaper,
    !checkReady()
  );

  const spinnerProps: any = {
    mx: 0,
    my: 10,
    text: "Processing...",
    disableShrink: true,
  };
  const spinner = isProcessing ? <Spinner {...spinnerProps} /> : null;

  const area = createTextField(
    "Area Name",
    handleChange,
    currentAreaName,
    false
  );
  const topic = createTextField(
    "Topic Name",
    handleChange,
    currentTopicName,
    false
  );
  const paperTitle = createTextField(
    "Paper title",
    handleChange,
    currentPaperTitle,
    false
  );
  const paperYear = createTextField(
    "Paper year",
    handleChange,
    currentPaperYear,
    false
  );
  const pubmedId = createTextField(
    "Pubmed ID",
    handleChange,
    currentPubmedId,
    false
  );

  const infoAreaName = createInfo(
    "Introduce area name. Do not use special characters (_, /, -, etc).",
    {
      marginBottom: 5,
    }
  );

  const infoTopicName = createInfo(
    "Introduce topic name. Do not use special characters (_, /, -, etc).",
    {
      marginBottom: 5,
    }
  );
  const infoPaperTitle = createInfo(
    "Enter the paper title of your first paper",
    {
      marginBottom: 10,
      marginTop: 30,
    }
  );
  const infoPaperYear = createInfo("Enter the paper year of your first paper", {
    marginBottom: 10,
  });
  const infoPubmedId = createInfo("Enter the pubmed ID if available.", {
    marginBottom: 10,
  });

  const removeTopic =
    currentUser === "alberto" || currentUser === "martin" ? (
      <RemoveTopicComponent
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    ) : null;

  return (
    <ViewFormLayout
      form={
        <>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {infoAreaName}
            {area}
            {infoTopicName}
            {topic}
            {infoPaperTitle}
            {paperTitle}
            {infoPaperYear}
            {paperYear}
            {infoPubmedId}
            {pubmedId}
            {importPubmedFileButton}
            {spinner}
          </Box>
          <br />
          {removeTopic}
          <br />
        </>
      }
      state={undefined}
      select={undefined}
      topActions={undefined}
      duplicate={undefined}
      info={undefined}
      table={undefined}
      validationAcronymForm={undefined}
      validationRegistryForm={undefined}
      recordActions={undefined}
      pdfActions={undefined}
      pdf={undefined}
      collapsablePdf={undefined}
      deleteButton={undefined}
      sourceSelectionButtons={undefined}
      importReview={undefined}
      excludeAll={undefined}
      exportPublication={undefined}
      validatePaperAnnotationsButton={undefined}
      updateButton={undefined}
    />
  );
}

const createTextField = (
  name: any,
  handleChange: any,
  currentValue: any,
  multiline: boolean
) => {
  return (
    <TextField
      label={name}
      style={{ marginBottom: 12 }}
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      fullWidth
      onChange={(e) => handleChange(name)(e)}
      value={currentValue}
      multiline={multiline}
    />
  );
};

const CreateButton = (onCreate: any, disabled: boolean) => {
  const createButtonColor: PropTypes.Color =
    buttonColor.create as PropTypes.Color;
  return (
    <Box>
      <Button
        component="span"
        variant="contained"
        disableRipple
        color={createButtonColor}
        size="medium"
        disabled={disabled}
        onClick={onCreate}
      >
        {
          <Typography variant="body2" display="block" align="center">
            Create area and topic
          </Typography>
        }
      </Button>
    </Box>
  );
};

const createInfo = (text: string, style: any) => {
  return (
    <Typography
      variant="subtitle1"
      display="block"
      align="center"
      color="primary"
      style={style}
    >
      {text}
    </Typography>
  );
};

type RemoveTopicComponentProps = {
  open: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
};

const RemoveTopicComponent = ({
  open,
  handleClickOpen,
  handleClose,
}: RemoveTopicComponentProps): any => {
  const [topicName, setTopicName] = React.useState("");

  const handleChange = (e: any) => {
    setTopicName(e.target.value);
  };
  const handleCloseButton = (e: any) => {
    setTopicName("");
    handleClose();
  };
  const handleRemove = (e: any) => {
    swal(
      "Not implemented",
      "Deleting topics is not yet implemented",
      "warning",
      {
        closeOnClickOutside: false,
      }
    );
    return;

    // api
    //   .deleteTopicFromPubmedSearch(topicName)
    //   .then((response) => {
    //     let format = "success";

    //     if (response.status !== 200) {
    //       format = "warning";
    //     }

    //     swal(response.data, response.data, format, {
    //       closeOnClickOutside: false,
    //     }).then((ok) => {
    //       setTopicName("");
    //       handleClose();
    //       window.location.reload();
    //     });
    //   })
    //   .catch((e) => {
    //     console.log("error: " + JSON.stringify(e.response));
    //     swal(
    //       "Error when removing topic. Inconsistent data. Send this message to SWT administrators.",
    //       e.response.data,
    //       "warning",
    //       {
    //         closeOnClickOutside: false,
    //       }
    //     ).then((ok) => {});
    //   });
  };

  return (
    <div>
      <Button
        disableRipple
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Remove topic
      </Button>
      <Dialog
        open={open}
        onClose={handleCloseButton}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Remove topic completely
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Remove pubmed search topic and all its papers, but those that belong
            to others topics.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="topicName"
            // type="email"
            fullWidth
            onChange={(e) => handleChange(e)}
            value={topicName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemove} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
