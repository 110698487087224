import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  PaperProps,
  TextField,
} from "@material-ui/core";

import Draggable from "react-draggable";
import SaveCancelDialogActions from "../../subcomponents/dialogs/SaveCancelDialogActions";
import { NewHighlight } from "../../../libs/react-pdf-highlighter/types";
import { calculateRandomId } from "../../../utils/utils";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import { ScaledPosition } from "../../../libs/react-pdf-highlighter/types";
import { emptyRectangle } from "../../../utils/annotation/geometricUtils";

const EmptyAnnotationContent = () => {
  const [error, setError] = React.useState(false);
  const [variableName, setVariableName] = React.useState("");
  const [annotationContent, setAnnotationContent] = React.useState("");

  const handleVariableNameChanged = (event: any) => {
    setVariableName(event.target.value);
  };

  const validateContent = (content: string) => {
    if (content !== null && (content === "mean" || content === "median")) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleAnnotationContentChanged = (event: any) => {
    validateContent(event.target.value);
    setAnnotationContent(event.target.value);
  };

  return {
    variableName,
    annotationContent,
    renderEmptyAnnotationContent: (
      <div>
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <TextField
            id="emptyAnnotationDialogVariableName"
            label={<strong>Variable name</strong>}
            autoFocus
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            value={variableName}
            onChange={(e) => handleVariableNameChanged(e)}
            helperText="Enter the variable name here e.g. prim1.result_A"
            size="small"
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <TextField
            id="emptyAnnotationDialogVariableContent"
            label={
              error ? (
                'Content should not be "mean" or "median" if can be derived automatically'
              ) : (
                <strong>Content</strong>
              )
            }
            style={{ margin: 8, width: "600px" }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            value={annotationContent}
            onChange={(e) => handleAnnotationContentChanged(e)}
            error={error}
            helperText='Enter the variable content here e.g. "heart disease" for variable condition'
            size="small"
          />
        </Box>
      </div>
    ),
  };
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface EmptyAnnotationDialogProps {
  addHighlight: (highlight: NewHighlight) => void;
  dialogClosed: () => void;
  open: boolean;
}

export const EmptyAnnotationDialog = ({
  addHighlight,
  dialogClosed,
  open,
}: EmptyAnnotationDialogProps) => {
  const [openDialog, setOpen] = React.useState(open);

  React.useEffect(() => {
    if (open) {
      setOpen(open);
    }
  }, [open]);

  const { variableName, annotationContent, renderEmptyAnnotationContent } =
    EmptyAnnotationContent();

  const createEmptyPosition = (): ScaledPosition => {
    return {
      boundingRect: emptyRectangle(),
      rects: [emptyRectangle()],
      pageNumber: 1,
      currentViewportScale: 1,
      currentOffsetX: 0,
      currentOffsetY: 0,
      currentRotation: 0,
      rotation: 0,
      pdfPageCoordinates: emptyRectangle(),
    };
  };

  const handleClose = (isCancelled: boolean) => {
    if (!isCancelled) {
      addHighlight({
        content: { text: annotationContent },
        position: createEmptyPosition(),
        comment: {
          text: variableName,
          count: "0",
        },
        chapter: "", // chapter is empty -> auto calculation in backend
        randomId: calculateRandomId(20),
        duplicateOfWithRandomId: "",
        annotationType: AnnotationType.EMPTY.type,
      });
    }
    dialogClosed();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {/* <Typography>Empty Annotation</Typography> */}
          Empty Annotation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Specify the annotation (mean or median should not be used), enter a
            value for variable name and annotation content
          </DialogContentText>
          {renderEmptyAnnotationContent}
        </DialogContent>
        <SaveCancelDialogActions
          handleClose={(isClosed: boolean) => handleClose(isClosed)}
          saveDisabled={
            variableName === null ||
            variableName.trim() === "" ||
            annotationContent === null ||
            annotationContent.trim() === ""
          }
        />
      </Dialog>
    </div>
  );
};

export default EmptyAnnotationDialogProps;
