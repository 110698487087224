/**
 * contains some utilities for filtering
 */

import {
  IHighlight,
  UserConfirmationStateHighlight,
} from "../../../libs/react-pdf-highlighter";
import { AnnotationChapter } from "../../../model/annotation/AnnotationChapter";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import { UserConfirmationState } from "../../../model/annotation/UserConfirmationState";
import { WorkflowStates } from "../../../model/WorkflowStates";

/**
 * Filter state which annotations are viewn to the user
 */
export const FilterState: any = {
  CONTENT: {
    state: "CONTENT",
    text: "Content",
  },
  EXTRACTED_CONTENT: {
    state: "EXTRACTED_CONTENT",
    text: "Extracted Content",
  },
  REGISTRY: {
    state: "REGISTRY",
    text: "Registry-Entries",
  },
  ICD: {
    state: "ICD",
    text: "ICD",
  },
  ALL: {
    state: "ALL",
    text: "All",
  },
};

export function getDefaultFilterByState(state: string): string {
  if (
    state === WorkflowStates.ANALYSIS.state ||
    state === WorkflowStates.ANALYSIS_REVIEW.state
  ) {
    return FilterState.CONTENT.state;
  } else if (state === WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING.state) {
    return FilterState.REGISTRY.state;
  } else if (state === WorkflowStates.EXTRACTION_VALIDATION_DATA.state) {
    return FilterState.ICD.state;
  }

  return FilterState.ALL.state;
}

export function getAllowedFiltersByState(state: string): Array<any> {
  if (
    state === WorkflowStates.ANALYSIS.state ||
    state === WorkflowStates.ANALYSIS_REVIEW.state
  ) {
    return [
      FilterState.CONTENT,
      FilterState.ICD,
      FilterState.REGISTRY,
      FilterState.ALL,
    ];
  } else if (
    state === WorkflowStates.EXTRACTION_VALIDATION_DATA.state ||
    state === WorkflowStates.RELEASE_VALIDATION.state ||
    state === WorkflowStates.RELEASE_VALIDATION_REVIEW.state
  ) {
    return [
      FilterState.EXTRACTED_CONTENT,
      FilterState.ICD,
      FilterState.REGISTRY,
      FilterState.ALL,
    ];
  }
  return [
    FilterState.EXTRACTED_CONTENT,
    FilterState.ICD,
    FilterState.REGISTRY,
    FilterState.ALL,
  ];
}

/**
 * get the filters for the basic annotations and the outcomes
 *
 * Filter for the basic annotations
 * @param highlights
 * @returns
 */
export function getBasicAndOutcomeFilters(
  highlights: Array<IHighlight>
): Array<string> {
  const filters: Array<string> = new Array<string>();
  let basicAnnotationsFound: boolean = false;

  AnnotationChapter.chapterList.forEach((c) => {
    // if basic already found, go on
    if (basicAnnotationsFound && !c.isResult) {
      return;
    }

    highlights.every((h) => {
      if (h.comment.text.includes(c.variableName)) {
        if (!c.isResult) {
          basicAnnotationsFound = true;
          return false;
        } else {
          if (filters.indexOf(c.variableName) !== -1) {
            filters.push(c.variableName);
          }
          if (!h.comment.text.includes("outcome")) {
            const outcomeSubsection = h.comment.text.match(
              c.variableName + "[0-9]+\\.(?:[0-9]+\\.)?"
            );

            if (outcomeSubsection !== null) {
              let outcomeSubSectionString = outcomeSubsection[0];
              outcomeSubSectionString = outcomeSubSectionString.substring(
                0,
                outcomeSubSectionString.length - 1
              );
              if (filters.indexOf(outcomeSubSectionString) === -1) {
                filters.push(outcomeSubSectionString);
              }
            }
          }
          return true;
        }
      }
      return true;
    });
  });
  filters.sort();

  if (basicAnnotationsFound) {
    filters.unshift("Basic");
  }

  // clean up the list
  if (
    filters.find((item) => {
      return item === AnnotationChapter.OUTCOME.variableName;
    }) &&
    filters.find((item) => {
      return (
        item === AnnotationChapter.PRIMARY_OUTCOME.variableName ||
        item === AnnotationChapter.SECONDARY_OUTCOME.variableName ||
        item === AnnotationChapter.TERTIARY_OUTCOME.variableName ||
        item === AnnotationChapter.TERTIARY_OUTCOME.variableName
      );
    })
  ) {
    filters.splice(filters.indexOf(AnnotationChapter.OUTCOME.variableName), 1);
  }
  return filters;
}

export function isIcdOrRegistryFilterActive(filterState: string) {
  return (
    filterState === FilterState.ICD.state ||
    filterState === FilterState.REGISTRY.state
  );
}

export function isHighlightVisibleInSidebar(
  highlight: IHighlight,
  filterState: string
) {
  if (
    highlight.annotationType === AnnotationType.ICD.type ||
    highlight.annotationType === AnnotationType.REGISTRY.type
  ) {
    const userHighlight: UserConfirmationStateHighlight =
      highlight as UserConfirmationStateHighlight;
    if (
      userHighlight.userConfirmationState === UserConfirmationState.DELETED.name
    ) {
      return false;
    }
  } else if (
    highlight.annotationType === AnnotationType.EMPTY.type &&
    FilterState.CONTENT.state === filterState
  ) {
    return true;
  }

  if (
    highlight.annotationType === filterState ||
    filterState === FilterState.ALL.state
  ) {
    return true;
  }

  // here we need to check for the variable specific filters
  if (filterState === "Basic") {
    let found = false;
    AnnotationChapter.chapterList.forEach((c) => {
      if (!c.isResult && highlight.comment.text.includes(c.variableName)) {
        found = true;
      }
    });
    return found;
  } else if (
    AnnotationChapter.chapterVariableList.find((item) => {
      return filterState.includes(item);
    })
  ) {
    let found = false;
    AnnotationChapter.chapterList.forEach((c) => {
      if (
        c.isResult &&
        highlight.comment.text.includes(c.variableName) &&
        filterState === c.variableName
      ) {
        found = true;
      }
    });
    if (!found) {
      AnnotationChapter.chapterList.forEach((c) => {
        if (c.isResult && highlight.comment.text.includes(filterState)) {
          found = true;
        }
      });
    }
    return found;
  }

  return false;
}

export function areIcdMenuItemsVisibleOnAnnotationPane(
  filterState: string
): boolean {
  return (
    filterState === FilterState.ICD.state ||
    filterState === FilterState.ALL.state
  );
}

export function areRegistryMenuItemsVisibleOnAnnotationPane(
  filterState: string
): boolean {
  return (
    filterState === FilterState.REGISTRY.state ||
    filterState === FilterState.ALL.state
  );
}

export function areBasicAndOutcomeMenuItemsVisibleOnAnnotationPane(
  filterState: string
): boolean {
  return (
    filterState === FilterState.CONTENT.state ||
    filterState === FilterState.ALL.state
  );
}
