import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import api from "../api";
import { UiPropertiesContext, UsersContext } from "./Context";

import {
  Analysis,
  EligibilityAssessment,
  Extraction,
  IncomingPapers,
  Miscellaneous,
  NavBar,
  NotFoundPage,
  Release,
  SourceSelection,
} from "./MainViews";
import { ROUTES } from "./routes";

const Administration = React.lazy(() => import("./administration/Administration"));

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    background: {
      default: "#ffffff",
    },
    primary: {
      main: "#253f5b",
    },
    secondary: {
      main: "#6f93ae",
    },
  },
  select: {
    "&:focus": {
      background: "white",
    },
  },
});

function Main() {
  let initLocation = localStorage.getItem("lastLocation")
    ? localStorage.getItem("lastLocation")
    : "/incoming-papers";
  // let initLocation = '/incoming-papers'
  const [users, setUsers] = React.useState();
  const [currentUser, setCurrentUser] = React.useState();
  const [uiProperties, setUiProperties] = React.useState();

  React.useEffect(() => {
    api.getUsers().then((data) => {
      setUsers(data.sort());
    });

    api.getUser().then((data) => setCurrentUser(data));
  }, []);

  React.useEffect(() => {
    api
      .getUiProperties("current")
      .then((response) => {
        if (response.status === 200) {
          setUiProperties(response.data);
        }
      })
      .catch((e) => console.log("getUiProperties error", e));
  }, []);

  if (!users || !uiProperties) return null;

  return (
    <UsersContext.Provider value={{ users, currentUser }}>
      <UiPropertiesContext.Provider value={{ uiProperties, setUiProperties }}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Container maxWidth="xl">
            <Routes>
              <Route
                path="/"
                element={<Navigate to={initLocation} replace />}
              />            
              <Route
                path={ROUTES.SOURCE_SELECTION}
                element={
                  <>
                    <NavBar />
                    <SourceSelection />
                  </>
                }
              />
              <Route
                path={ROUTES.INCOMING_PAPERS}
                element={
                  <>
                    <NavBar />
                    <IncomingPapers />
                  </>
                }
              />
              <Route
                path={ROUTES.ELIGIBILITY_ASSESSMENT}
                element={
                  <>
                    <NavBar />
                    <EligibilityAssessment />
                  </>
                }
              />
              <Route
                path={ROUTES.PAPER_ANALYSIS}
                element={
                  <>
                    <NavBar />
                    <Analysis />
                  </>
                }
              />
              <Route
                path={ROUTES.EXTRACTION}
                element={
                  <>
                    <NavBar />
                    <Extraction />
                  </>
                }
              />
              <Route
                path={ROUTES.RELEASE}
                element={
                  <>
                    <NavBar />
                    <Release />
                  </>
                }
              />
              <Route
                path={ROUTES.MISCELLANEOUS}
                element={
                  <>
                    <NavBar />
                    <Miscellaneous />
                  </>
                }
              />
              <Route path={ROUTES.ADMINISTRATION} element={<Suspense fallback={<div>Loading...</div>}> <Administration /> </Suspense>} />
              <Route
                path="*"
                element={
                  <>
                    <NavBar />
                    <NotFoundPage />
                  </>
                }
              />
            </Routes>
          </Container>
        </MuiThemeProvider>
      </UiPropertiesContext.Provider>
    </UsersContext.Provider>
  );
}

export default Main;
