import { Box, Button, Input, InputLabel, Typography } from "@material-ui/core";
import PubSub from "pubsub-js";
import { useSnackbar } from "notistack";
import { PubSubMessage } from "../../utils/communication/PubSubTypes";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import api from "../../api";
import { ViewProperties } from "../../model/ViewProperties";
import { UsersContext } from "../Context";
import { ExportActions } from "../subcomponents/actionComponents/ExportActions";
import { PdfActions } from "../subcomponents/actionComponents/PdfActions";
import { RecordActions } from "../subcomponents/actionComponents/RecordActions";
import DeleteActions from "../subcomponents/actionComponents/DeleteActions";
import SourceSelectionButtons from "../subcomponents/actionComponents/SourceSelectionActions";
import TopActions from "../subcomponents/actionComponents/TopActions";
import { ActionButton } from "../subcomponents/buttons/ActionButton";
import { buttonColor } from "../subcomponents/buttons/buttonProperties";
import ExcludeAll from "../subcomponents/buttons/ExcludeAll";
import { ImportReviewButton } from "../subcomponents/buttons/ImportReviewButton";
import { NoAreasFound } from "../subcomponents/NoAreasFound";
import InfoCard from "../subcomponents/notification/InfoCard";
import { successSnackbarOptions } from "../subcomponents/notification/notifications";
import Spinner from "../subcomponents/notification/Spinner";
import CollapsablePdfViewer from "../subcomponents/pdf/CollapsablePdfViewer";
import PdfViewer from "../subcomponents/pdf/PdfViewer";
import RecordForm from "../subcomponents/record/RecordForm";
import AreaTopicSelect from "../subcomponents/selectors/AreaTopicSelectNew";
import RecordTableList from "../subcomponents/tables/RecordTableList";
import RecordTablePubParent from "../subcomponents/tables/RecordTablePubParent";
import AcronymsValidation from "../subcomponents/validation/AcronymsValidation";
import { ValidatePaperAnnotationsButton } from "../subcomponents/buttons/ValidatePaperAnnotationsButton";
import {
  fetchAreasAndTopics,
  fetchPPs,
  fetchRecords,
  fetchTopics,
  getIdAndIdx,
} from "./fetchData";
import ViewFormLayout from "./layouts/ViewFormLayout";
import RegistriesValidation from "../subcomponents/validation/RegistriesValidation";
import { alertConfirm } from "../subcomponents/notification/notificationDialogs";


function ViewForm({ state }) {
  const [recordList, setRecordList] = useState([]);
  const [publicationParentList, setPublicationParentList] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentAreaId, setCurrentAreaId] = useState();
  const [currentTopicId, setCurrentTopicId] = useState();
  const [disableActions, setDisableActions] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [resetTableIndex, setResetTableIndex] = useState(false);
  const [updatePdfViewer, setUpdatePdfViewer] = useState(false);
  const [isPapersLoading, setIsPapersLoading] = useState(false);

  const [areas, setAreas] = useState([]);
  const [topics, setTopics] = useState([]);

  const [done, setDone] = useState(false);

  // const [responsibilities, setResponsibilities] = useState();

  const location = useLocation();
  const { users, currentUser } = useContext(UsersContext);
  // console.log(users);
  // console.log(currentUser);

  const {
    showExportPublicationButton,
    showPdf,
    showPdfActions,
    showRecordActions,
    showDeleteButton,
    showSourceSelectionButtons,
    showImportReview,
    showPP,
    showExcludeAll,
    hasResponsibility,
    showDuplicate,
    showAcronymValidationForm,
    showRegistryValidationForm,
    showCollapsablePdf,
    showValidatePaperAnnotations,
    showUpdate,
  } = ViewProperties[state];

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchAreasAndTopics(
      state,
      setAreas,
      setCurrentAreaId,
      setTopics,
      setCurrentTopicId,
      location,
      setDone
    );

    return () => {
      setAreas([]);
      setTopics([]);
    };
  }, [state, location]);

  useEffect(() => {
    if (currentTopicId) {
      if (showPP) {
        fetchPPs(
          state,
          currentTopicId,
          setPublicationParentList,
          setRecordList,
          setCurrentRecord,
          setResetTableIndex,
          setIsPapersLoading
        );
      } else {
        fetchRecords(
          state,
          currentTopicId,
          setRecordList,
          setCurrentRecord,
          setResetTableIndex,
          "fromTopic"
        );
      }
    }

    return () => {
      setRecordList([]);
      setPublicationParentList([]);
    };
  }, [state, currentTopicId, refreshRecordList, showPP]);

  const handleRefreshRecordList = useCallback(() => {
    setRefreshRecordList((refresh) => !refresh);
  }, []);

  const handleUpdatePdfViewer = useCallback(() => {
    setUpdatePdfViewer((updatePdfViewer) => !updatePdfViewer);
  }, []);

  const handleRefreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleDisabled = useCallback((disable) => {
    setDisableActions(!disable);
  }, []);

  const fetchDataReady =
    currentAreaId && currentTopicId && areas.length > 0 && topics.length > 0;

  if (done && !currentAreaId) {
    return <NoAreasFound showImportReview={showImportReview} />;
  }

  if (!fetchDataReady) {
    return <ViewFormLayout />;
  }
  const reloadPage = () => {
    window.alert(
      "This topic has no records in this view. Probably you moved the last record from another topic (duplicated). The page will be updated after clicking OK."
    );
    window.location.reload();
  };

  const handleChangeArea = (idArea) => {
    setCurrentRecord(null);
    setCurrentTopicId(null);
    setTopics([]);
    setCurrentAreaId(idArea);
    fetchTopics(state, idArea, setTopics, setCurrentTopicId, reloadPage);
    api.setAreaForUser(state, null, idArea);
  };

  const handleChangeTopic = (idTopic) => {
    setCurrentTopicId(idTopic);
    api.setTopicForUser(state, null, idTopic);
  };

  const handleCurrentRecordIdx = (recordId) => {
    const idx = recordList.findIndex(({ id }) => id === recordId);
    setCurrentRecord(recordList[idx]);
  };

  const saveRecord = (record) => {
    updateRecord(record);
    api.saveRecord(record);
  };

  const updateRecord = (record) => {
    const idx = recordList.findIndex(({ id }) => id === record.id);
    let listCopy = [...recordList];
    listCopy[idx] = record;
    setRecordList(listCopy);
    setCurrentRecord(record);
  };

  const handleExclusionSelection = (newExclusionState) => {
    const newRecord = { ...currentRecord };
    newRecord.exclusionState = newExclusionState;
    saveRecord(newRecord);
  };

  const onDuplicateFile = () => {
    let msg = "Do you really want to duplucate the selected file?";
    alertConfirm("Duplicate File", msg, onDuplicateFileConfirmed);
  };

  const onDuplicateFileConfirmed = () => {
    api
      .createDuplicate(currentAreaId, currentTopicId, currentRecord.id)
      .then((response) => {
        if (response.status !== 200) {
          swal("Error when creating duplicate", response.data, "warning", {
            closeOnClickOutside: false,
          });
        } else {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log("duplicate error: " + e);
      });
  };

  var responsible;

  if (hasResponsibility && areas.length > 0 && currentAreaId) {
    let idAndIdx = getIdAndIdx(areas, currentAreaId);
    let responsibilities = areas[idAndIdx.idx].responsibilities;
    if (responsibilities) {
      responsible = areas[idAndIdx.idx].responsibilities[state];

      if (!users.includes(responsible)) {
        responsible = null;
      }
    }
  }

  let blockArea = responsible && responsible !== currentUser;

  const Selector = (
    <AreaTopicSelect
      areas={areas}
      topics={topics}
      initialAreaId={currentAreaId}
      initialTopicId={currentTopicId}
      handleChangeArea={handleChangeArea}
      handleChangeTopic={handleChangeTopic}
      responsible={responsible}
    />
  );

  const RecordTable = showPP ? (
    <RecordTablePubParent
      publicationParentList={publicationParentList}
      handleCurrentRecordIdx={handleCurrentRecordIdx}
      resetIndex={resetTableIndex}
      disableActions={disableActions}
    />
  ) : (
    <RecordTableList
      recordList={recordList}
      handleCurrentRecordIdx={handleCurrentRecordIdx}
      resetIndex={resetTableIndex}
      disableActions={disableActions}
    />
  );

  const onPdfUpdate = (e) => {
    api
      .updatePdfFileWithoutStateChange(currentRecord, e.target.files[0])
      .then((data) => {
        updateRecord(data);
        enqueueSnackbar("Pdf successfully uploaded", successSnackbarOptions);
        handleUpdatePdfViewer();
        setUpdatePdfViewer(true);
        // update PdfViewer
      })
      .catch((e) => console.log("move forward error: " + e));
  };

  const updateButton = (<UpdateButton onPdfUpdate={onPdfUpdate} disabled={false} />);
  const validatePaperAnnotationsButton = PaperAnnotationsValidateButton(
    currentRecord,
    false
  );

  const spinner = isPapersLoading ? (
    <Spinner mx={10} my={5} disableShrink />
  ) : null;

  return (
    <React.Fragment>
      <ViewFormLayout
        state={state}
        select={Selector}
        topActions={
          currentRecord && <TopActions handleRefreshPage={handleRefreshPage} />
        }
        importReview={showImportReview && <ImportReviewButton />}
        table={
          publicationParentList.length > 0 || recordList.length > 0
            ? RecordTable
            : spinner
        }
        form={
          currentRecord && (
            <RecordForm
              state={state}
              currentRecord={currentRecord}
              saveRecord={saveRecord}
              handleDisabled={handleDisabled}
              key={refreshRecordList} //force to update component
              updateRecord={updateRecord}
            />
          )
        }
        validationAcronymForm={
          showAcronymValidationForm &&
          currentRecord && (
            <AcronymsValidation
              currentRecord={currentRecord}
              saveRecord={saveRecord}
            />
          )
        }
        validationRegistryForm={
          showRegistryValidationForm &&
          currentRecord && (
            <RegistriesValidation
              currentRecord={currentRecord}
            />
          )
        }
        pdf={
          showPdf &&
          currentRecord && (
            <PdfViewer
              state={state}
              currentRecord={currentRecord}
              key={updatePdfViewer}
            />
          )
        }
        collapsablePdf={
          currentRecord &&
          showCollapsablePdf && (
            <CollapsablePdfViewer state={state} currentRecord={currentRecord} />
          )
        }
        pdfActions={
          showPdfActions &&
          currentRecord && (
            <PdfActions
              state={state}
              currentRecord={currentRecord}
              disableActions={disableActions || blockArea}
              updateRecord={updateRecord}
              handleRefreshPage={handleRefreshPage}
              handleUpdatePdfViewer={handleUpdatePdfViewer}
            />
          )
        }
        recordActions={
          showRecordActions &&
          currentRecord && (
            <RecordActions
              state={state}
              currentRecord={currentRecord}
              disableRecordActions={disableActions || blockArea}
              updateRecord={updateRecord}
              handleExclusionSelection={handleExclusionSelection}
            // key={refresh}
            />
          )
        }
        deleteButton={
          showDeleteButton &&
          currentRecord && (
            <DeleteActions
              state={state}
              currentRecord={currentRecord}
              disableActions={disableActions}
            />
          )
        }
        sourceSelectionButtons={
          showSourceSelectionButtons &&
          currentRecord && (
            <SourceSelectionButtons
              state={state}
              area={currentAreaId}
              topic={currentTopicId}
              currentRecord={currentRecord}
              disableActions={disableActions}
              handleRefreshRecordList={handleRefreshRecordList}
            />
          )
        }
        duplicate={
          showDuplicate &&
          currentRecord &&(
            <ActionButton
              text="Duplicate Record"
              captionText={null}
              disabled={false}
              handleClick={onDuplicateFile}
              color="primary"
           />
          )
        }
        exportPublication={
          showExportPublicationButton &&
          currentRecord && <ExportActions currentRecord={currentRecord} />
        }
        info={currentRecord && <InfoCard record={currentRecord} />}
        validatePaperAnnotationsButton={
          currentRecord &&
          showValidatePaperAnnotations &&
          validatePaperAnnotationsButton
        }
        updateButton={currentRecord && showUpdate && updateButton}
        excludeAll={
          showExcludeAll &&
          currentRecord &&
          currentTopicId && (
            <ExcludeAll
              currentTopicId={currentTopicId}
              state={state}
              disableActions={disableActions || blockArea}
              handleRefreshPage={handleRefreshPage}
            />
          )
        }
      />
      {/* </Route> */}
    </React.Fragment>
  );
}

function UpdateButton(onPdfUpdate, disabled) {
  const [disabledByEvent, setDisabledByEvent] = React.useState(false);

  let disableEventSubscriber = null;
  // The disable event is of type PubSubBooleanType.
  var mySubscriber = function (msg, data) {
    setDisabledByEvent(data.value);
  };
  disableEventSubscriber = PubSub.subscribe(PubSubMessage.DISABLE_ACTION_EVENT, mySubscriber);
  useEffect(() => {
    return () => {
      if (disableEventSubscriber != null) {
        PubSub.unsubscribe(disableEventSubscriber);
      }
    };
  });

  return (
    <InputLabel htmlFor="update">
      <Input
        style={{ display: "none" }}
        id="update"
        name="update"
        type="file"
        value="" // hack to trigger onChange, even when updating same file
        onChange={onPdfUpdate}
        disabled={disabled}
      />
      <Box mt={2} display="flex" flexDirection="column" alignItems="center">
        <Box>
          <Button
            component="span"
            variant="contained"
            disableRipple
            color={buttonColor.update}
            size="medium"
            disabled={disabled || disabledByEvent}
          >
            {
              <Typography variant="body2" display="block" align="center">
                Update PDF
              </Typography>
            }
          </Button>
        </Box>
        <Box>
          <Typography
            variant="caption"
            display="block"
            // align="center"
            color="textSecondary"
          >
            {"Update file without workflow state change"}
          </Typography>
        </Box>
      </Box>
    </InputLabel>
  );
}

function PaperAnnotationsValidateButton(currentRecord, disabled) {
  return (
    <InputLabel htmlFor="validatePaperAnnotatios">
      <Input
        style={{ display: "none" }}
        id="validatePaperAnnotatios"
        name="validatePaperAnnotatios"
        value="" // hack to trigger onChange, even when updating same file
        disabled={disabled}
      />
      <Box mt={2} display="flex" flexDirection="column" alignItems="center">
        <ValidatePaperAnnotationsButton
          currentRecord={currentRecord}
          useWhiteOutclinedButton={false}
        />
        <Box>
          <Typography variant="caption" display="block" color="textSecondary">
            {"validation of uploaded paper or in UI annotated"}
          </Typography>
        </Box>
      </Box>
    </InputLabel>
  );
}

// const NoAreasFound = ({ showImportReview }) => {
//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//       minHeight="100vh"
//     >
//       <Box>
//         <Typography variant="h2" color="secondary">
//           No areas in this view
//         </Typography>
//       </Box>
//       {showImportReview && (
//         <Box mt={5} justifyContent="center">
//           <ImportReviewButton />
//         </Box>
//       )}
//     </Box>
//   );
// };

export default ViewForm;
