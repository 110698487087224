import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import axios from "axios";
import React from "react";
import Highlighter from "react-highlight-words";
import { UiPropertiesContext } from "../../Context";

const initialState = {
  mouseX: null,
  mouseY: null,
};

export const HighlightedField = ({ text, detectionResults, field }) => {
  const [state, setState] = React.useState(initialState);
  const [textSelected, setTextSelected] = React.useState("");
  const [textWiki, setTextWiki] = React.useState("");

  const { uiProperties } = React.useContext(UiPropertiesContext);

  const handleMouseUp = () => {
    let text = window.getSelection().toString();
    setTextSelected(text);
    if (text !== "") {
      getWiki(text)
        .then((response) => {
          setTextWiki(response.data.extract);
        })
        .catch((e) => {
          console.log("wiki not found: " + e);
          setTextWiki("Wiki not found");
        });
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY + 14,
    });
  };

  const handleCloseMenu = () => {
    setState(initialState);
    setTextSelected("");
    setTextWiki("");
  };

  const handleClickWiki = () => {
    setState(initialState);
    const wikiUrl = "https://en.wikipedia.org/wiki/" + textSelected;
    window.open(wikiUrl);
  };

  const handleClickGoogle = () => {
    const url = "https://www.google.com/search?q=" + textSelected;
    window.open(url);
  };

  const getTermsToHighlight = () => {
    let includedKeywordsRegex = uiProperties.includedKeywordsRegex.keywords.map(
      (k) => {
        return new RegExp(k);
      }
    );
    let excludedKeywordsRegex = uiProperties.excludedKeywordsRegex.keywords.map(
      (k) => {
        return new RegExp(k);
      }
    );

    let neutralKeywordsRegex = uiProperties.neutralKeywordsRegex.keywords.map(
      (k) => {
        return new RegExp(k);
      }
    );

    let termsToHighlight = uiProperties.includedKeywords.keywords
      .concat(uiProperties.excludedKeywords.keywords)
      .concat(uiProperties.neutralKeywords.keywords)
      .concat(includedKeywordsRegex)
      .concat(excludedKeywordsRegex)
      .concat(neutralKeywordsRegex);

    if (detectionResults) {
      termsToHighlight = detectionResults.concat(termsToHighlight);
    }

    if (field === "abstractText") {
      termsToHighlight = termsToHighlight.concat(
        uiProperties.sectionKeywords.keywords
      );
    }

    return termsToHighlight;
  };

  const getHighlightedStyle = (child) => {
    let highlightedStyle;
    if (
      uiProperties.includedKeywords.keywords.includes(child.toLowerCase()) ||
      uiProperties.includedKeywordsRegex.keywords.some((e) => child.match(e))
    ) {
      highlightedStyle = {
        backgroundColor: uiProperties.includedKeywords.color,
      };
    } else if (
      uiProperties.excludedKeywords.keywords.includes(child.toLowerCase()) ||
      uiProperties.excludedKeywordsRegex.keywords.some((e) => child.match(e))
    ) {
      highlightedStyle = {
        backgroundColor: uiProperties.excludedKeywords.color,
      };
    } else if (
      uiProperties.neutralKeywords.keywords.includes(child.toLowerCase()) ||
      uiProperties.neutralKeywordsRegex.keywords.some((e) => child.match(e))
    ) {
      highlightedStyle = {
        backgroundColor: uiProperties.neutralKeywords.color,
      };
    } else if (
      uiProperties.sectionKeywords.keywords.includes(child.toLowerCase())
    ) {
      highlightedStyle = {
        backgroundColor: uiProperties.sectionKeywords.color,
      };
    } else {
      highlightedStyle = {
        backgroundColor: uiProperties.designVariableKeywords.color,
      };
    }

    return highlightedStyle;
  };

  const Highlight = ({ children }) => {
    const style = getHighlightedStyle(children);

    return <mark style={style}>{children}</mark>;
  };

  let disableAll = textSelected === "";
  let wikiElem = <p style={{ color: "white", fontSize: "12px" }}>{textWiki}</p>;
  let termsToHighlight = getTermsToHighlight();

  return (
    <div onContextMenu={handleClick}>
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={termsToHighlight}
        autoEscape={false}
        textToHighlight={text ? text.toString() : "Not available"}
        highlightTag={Highlight}
        caseSensitive={false}
        onMouseUp={handleMouseUp}
      />
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
        disableAutoFocusItem
      >
        <MenuItem disabled={disableAll || textWiki === "Wiki not found"}>
          <Tooltip arrow title={wikiElem} placement="right">
            <span>summary</span>
          </Tooltip>
        </MenuItem>
        <MenuItem
          disabled={disableAll || textWiki === "Wiki not found"}
          onClick={handleClickWiki}
        >
          go to wikipedia
        </MenuItem>
        <MenuItem disabled={disableAll} onClick={handleClickGoogle}>
          search in google
        </MenuItem>
      </Menu>
    </div>
  );
};

async function getWiki(text) {
  let url =
    "https://en.wikipedia.org/api/rest_v1/page/summary/" + text.toLowerCase();

  const response = await axios.get(url);
  return response;
}
