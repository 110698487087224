import {
  Box,
  Button,
  Checkbox,
  Chip,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  fetchRegistryAnnotationsForRecord,
  updateIcdOrRegistryAnnotationList
} from "../../../api/annotationApi";
import {
  BasicAnnotations,
  RegistryEntryAnnotation,
  RegistryEntriesForPublicationAnnotations,
} from "../../../model/annotation/Annotation";
import {
  createLinkToRegistry,
  createRegistryName,
} from "../../../utils/validation/RegistryUtils";
import React from "react";
import { ActionButtonStyled } from "../buttons/ActionButton";
import { UserConfirmationState } from "../../../model/annotation/UserConfirmationState";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import FieldLink from "../record/FieldLink";

interface RegistriesValidationProps {
  currentRecord: any;
}

interface RegistryEntryAnnotationWithId extends RegistryEntryAnnotation {
  id: number; // The id is not in the database and has to be generated manually
}

interface RegistryEntriesWithId extends BasicAnnotations {
  registryEntries: Array<RegistryEntryAnnotationWithId>;
}

/**
 * Registry validation form which contains the validation for the registries. The user can validate and
 * modify them with a check box. If no registry entries are available a text shows up that no registry
 * entries are available.
 * 
 * @param currentRecord the current record that contains the registry entries. 
 * @returns The form.
 */
function RegistriesValidation({ currentRecord }: RegistriesValidationProps) {
  const [registryEntriesForPaper, setRegistryEntries] = React.useState<RegistryEntriesWithId>();

  React.useEffect(() => {
    fetchRegistryAnnotationsForRecord(currentRecord.id).then((response: any) => {
      if (response == null || response === '') {
        return;
      }
      const registryEntriesForPaper: RegistryEntriesForPublicationAnnotations = jsonCopy(response);
      const registryEntriesForPaperLoc: RegistryEntriesWithId = {
        id: registryEntriesForPaper.id,
        paperId: registryEntriesForPaper.paperId,
        registryEntries: []
      };
      
      registryEntriesForPaper.registryEntries.forEach((entry, index) => {
        registryEntriesForPaperLoc.registryEntries.push(
          {
            ...entry,
            id: index
          }
        );
      });
      setRegistryEntries({ ...registryEntriesForPaperLoc });
    });
  }, [currentRecord]);

  const handleSave = (editedRegistryContent: RegistryEntryAnnotationWithId) => {
    var idx: number | undefined = registryEntriesForPaper?.registryEntries.findIndex(
      (x) => x.id === editedRegistryContent.id
    );

    if (idx !== undefined) {
      registryEntriesForPaper!.registryEntries[idx] = editedRegistryContent;
      setRegistryEntries({ ...registryEntriesForPaper! });
      updateIcdOrRegistryAnnotationList(registryEntriesForPaper!.registryEntries, currentRecord.id,
        AnnotationType.REGISTRY.url)
    } else {
      console.log("Element index not found when saving");
    }
  };

  const toggleState = (registryEntryToDisable: RegistryEntryAnnotationWithId) => {
    var idx: number | undefined = registryEntriesForPaper?.registryEntries.findIndex(
      (x) => x.id === registryEntryToDisable.id
    );

    if (idx !== undefined) {
      if (registryEntryToDisable.userConfirmationState === UserConfirmationState.DELETED.name) {
        registryEntryToDisable.userConfirmationState = UserConfirmationState.DEFAULT.name;
      } else {
        registryEntryToDisable.userConfirmationState = UserConfirmationState.DELETED.name;
      }
      registryEntriesForPaper!.registryEntries[idx] = registryEntryToDisable;
      setRegistryEntries({ ...registryEntriesForPaper! });
      updateIcdOrRegistryAnnotationList(registryEntriesForPaper!.registryEntries, currentRecord.id,
        AnnotationType.REGISTRY.url)
    } else {
      console.log("Element index not found when saving");
    }
  };

  const headerInfo = (registryEntriesForPaper: RegistryEntriesWithId) => {
    if (registryEntriesForPaper?.registryEntries?.length !== 0) {
      return (<></>);
    }
    return (
      <>
        <Box display="flex" justifyContent="center">
          <Chip size="small" label={"No registry entries found."} variant="outlined" />
        </Box>
      </>
    );
  };

  /**
   * Sets the default value to all the registry entries.
   */
  const restoreDisabledAnnotations = () => {
    if (registryEntriesForPaper !== undefined) {
      const newRegistryEntriesForPaper: RegistryEntriesWithId = registryEntriesForPaper!;
      newRegistryEntriesForPaper!.registryEntries.forEach((entry, index) => {
        entry.userConfirmationState = UserConfirmationState.DEFAULT.name;
      });

      setRegistryEntries({
        ...newRegistryEntriesForPaper,
        registryEntries: newRegistryEntriesForPaper!.registryEntries
      });
      updateIcdOrRegistryAnnotationList(
        newRegistryEntriesForPaper.registryEntries,
        newRegistryEntriesForPaper.paperId,
        AnnotationType.REGISTRY.url
      );
    }
  };

  // TODO: Unterscheiden beim Laden nach der Quelle, z.B. Pubmed.
  return (
    <div>
      {(registryEntriesForPaper !== undefined && registryEntriesForPaper.registryEntries.length > 0) ?
        <Typography variant="h6" color="primary">
          Validation of registry entries
        </Typography>
        : null}
      <List subheader={headerInfo(registryEntriesForPaper!)}>
        {registryEntriesForPaper !== undefined &&
          registryEntriesForPaper.registryEntries.length !== 0 &&
          registryEntriesForPaper?.registryEntries.map((registryEntry, idx) => {
            return (
              <React.Fragment key={idx + registryEntry.importDataSource!}>
                <RegistryItem
                  registryEntry={registryEntry}
                  handleSave={handleSave}
                  toggleState={toggleState}
                />
              </React.Fragment>
            );
          })}
      </List>

      {(registryEntriesForPaper !== undefined && registryEntriesForPaper.registryEntries.length > 0) ?
        <Button
          onClick={restoreDisabledAnnotations}
          disableRipple
          color="secondary"
          size="medium"
          variant="contained"
          style={{ marginTop: "2rem" }}>
          Restore all disabled annotations
        </Button>
        : null}
    </div>
  );
}

interface RegistryItemProps {
  registryEntry: RegistryEntryAnnotationWithId;
  handleSave: Function;
  toggleState: Function;
}

function RegistryItem({ registryEntry, handleSave, toggleState }: RegistryItemProps): JSX.Element | null {
  const [editedRegistryEntryAnnotationWithId, setEditedRegistryEntryAnnotationWithId] = React.useState<RegistryEntryAnnotationWithId>();
  const [lastRegistryEntryAnnotationWithId, setLastRegistryEntryAnnotationWithId] = React.useState<RegistryEntryAnnotationWithId>();
  const disableEditFields = registryEntry.userConfirmationState !== UserConfirmationState.DEFAULT.name;
  const disableToggleStatus = registryEntry.userConfirmationState !== UserConfirmationState.DEFAULT.name
    && registryEntry.userConfirmationState !== UserConfirmationState.DELETED.name;
  const disableValidationCheckbox = registryEntry.userConfirmationState === UserConfirmationState.DELETED.name;
  const checkBoxAccepted = registryEntry.userConfirmationState === UserConfirmationState.ACCEPTED.name;

  React.useEffect(() => {
    if (registryEntry !== null) {
      setEditedRegistryEntryAnnotationWithId(jsonCopy(registryEntry));
      setLastRegistryEntryAnnotationWithId(jsonCopy(registryEntry));
    }
  }, [registryEntry]);

  const handleChange = (e: any) => {
    let newRegistryEntryAnnotationWithId: RegistryEntryAnnotationWithId = editedRegistryEntryAnnotationWithId!;
    newRegistryEntryAnnotationWithId.registryId = e.target.value;
    setEditedRegistryEntryAnnotationWithId((editedRegistryEntryAnnotationWithId: RegistryEntryAnnotationWithId | undefined) => {
      return { ...editedRegistryEntryAnnotationWithId, ...newRegistryEntryAnnotationWithId };
    });
  };

  const onSaveClick = () => {
    setLastRegistryEntryAnnotationWithId((lastRegistryEntryAnnotationWithId: any) => {
      return { ...lastRegistryEntryAnnotationWithId, ...editedRegistryEntryAnnotationWithId };
    });

    handleSave(editedRegistryEntryAnnotationWithId);
  };

  const onDisableClick = () => {
    toggleState(editedRegistryEntryAnnotationWithId);
  };

  if (!editedRegistryEntryAnnotationWithId) return null;

  var enableDisableRegistryEntryBtn = (<ActionButtonStyled
    text={registryEntry.userConfirmationState === UserConfirmationState.DELETED.name ? "Enable" : "Disable"}
    captionText=""
    disabled={disableToggleStatus}
    handleClick={onDisableClick}
    btnType={registryEntry.userConfirmationState === UserConfirmationState.DELETED.name ? "danger" : "yellow" }
    size="small"
  />)

  const handleChangeCheckBox = (event: any, registryContent: RegistryEntryAnnotationWithId) => {
    setLastRegistryEntryAnnotationWithId((lastRegistryEntryAnnotationWithId: any) => {
      return { ...lastRegistryEntryAnnotationWithId, ...editedRegistryEntryAnnotationWithId };
    });

    if (event.target.checked) {
      registryContent.userConfirmationState = UserConfirmationState.ACCEPTED.name;
    } else {
      registryContent.userConfirmationState = UserConfirmationState.DEFAULT.name;
    }

    handleSave(registryContent);
  };

  const registryEntryLink = createLinkToRegistry(registryEntry.registryType, registryEntry.registryId);

  return (
    <div>
      <ListItem>
        <Box ml={2}>
          <Typography
            style={{
              marginRight: 20,
              minWidth: 150,
              // color: checked ? "green" : "orangeRed",
            }}
          >
            {createRegistryName(registryEntry.importDataSource)}
          </Typography>
        </Box>
        {registryEntryLink !== null ?
          <Box ml={2}>
            <FieldLink field={"url"} value={registryEntryLink} />
          </Box>
          : null}
        <Box
          ml={2}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flexGrow={1}
        >
          <TextField
            // style={{ width: "100%" }}
            id="annotation"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            margin="dense"
            value={editedRegistryEntryAnnotationWithId.registryId}
            onChange={handleChange}
            error={editedRegistryEntryAnnotationWithId.registryId !== lastRegistryEntryAnnotationWithId!.registryId}
            disabled={disableEditFields}
          />
        </Box>
        <Box ml={2}>
          <Button
            variant="contained"
            disableRipple
            onClick={() => {
              onSaveClick();
            }}
            color="primary"
            disabled={disableEditFields || editedRegistryEntryAnnotationWithId.registryId === lastRegistryEntryAnnotationWithId!.registryId}
            size="small"
          >
            <Typography variant="body2" align="center">
              Save
            </Typography>
          </Button>
        </Box>
        <Box ml={2}>{enableDisableRegistryEntryBtn}</Box>
        <Checkbox
          checked={checkBoxAccepted}
          disabled={disableValidationCheckbox}
          onChange={(e) => handleChangeCheckBox(e, editedRegistryEntryAnnotationWithId)}
        />
      </ListItem>
    </div>
  );
};

function jsonCopy(src: any) {
  return JSON.parse(JSON.stringify(src));
}

export default RegistriesValidation;
