import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { buttonColor } from "./buttonProperties";
import { makeStyles } from "@material-ui/core/styles";
import PdfAnnotationApp from "../../annotation/PdfAnnotationApp";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import api, { Action } from "../../../api";
import { useSnackbar } from "notistack";
import { successSnackbarOptions } from "../notification/notifications";
import { AnnotationToolProperties } from "../../../model/ViewProperties";

import { ValidatePaperAnnotationsButton } from "./ValidatePaperAnnotationsButton";
import {
  getOpenAnnotationUiButtonNameDependentOnWorkflowState,
  isAnnotationUiOpenButtonEnabled,
} from "../../../utils/annotation/uiUtils";
import AnnotationToolsMenu from "./AnnotationToolsMenu";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export const AnnotationUiOpenButton = ({
  state,
  currentRecord,
  updateRecord,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [eventBus, setEventBus] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const pdfAnnotationApp = React.createRef();

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClickForward = () => {
    api
      .moveRecord(currentRecord, Action.FORWARD)
      .then((data) => {
        console.log(data);
        updateRecord(data);
        enqueueSnackbar("Record successfully moved", successSnackbarOptions);
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const onSearchTextChange = (e) => {
    if (eventBus !== null) {
      setSearchText(e.target.value);
      eventBus.dispatch("find", {
        type: "",
        query: e.target.value,
        highlightAll: true,
        phraseSearch: true,
        caseSensitive: false,
      });
    }
  };

  const onSearchTextKeyPress = (e) => {
    if (eventBus !== null) {
      if (e.key === "Enter") {
        eventBus.dispatch("find", {
          type: "again",
          query: searchText,
          phraseSearch: true,
          caseSensitive: false,
          highlightAll: true,
          findPrevious: false,
        });
      }
      setSearchText(e.target.value);
    }
  };

  const onNextResult = (e) => {
    eventBus.dispatch("find", {
      type: "again",
      query: searchText,
      caseSensitive: false,
      highlightAll: true,
      findPrevious: false,
    });
  };

  const onPrevResult = (e) => {
    eventBus.dispatch("find", {
      type: "again",
      query: searchText,
      caseSensitive: false,
      highlightAll: true,
      findPrevious: true,
    });
  };

  let disabled = isAnnotationUiOpenButtonEnabled(state, currentRecord);
  const { showForwardButton } = AnnotationToolProperties[state] || false;

  return (
    <div>
      <Tooltip
        title="Enabled when non-annotated paper has been forwarded from Manual Assessment since 
      introduction of the PDF annotation UI. If papers have been manually annotated and uploaded the button is also disabled"
        enterDelay={500}
      >
        <span>
          <Button
            variant="contained"
            disableRipple
            onClick={handleClickOpen}
            color={buttonColor.secondary}
            disabled={disabled}
            size="small"
          >
            <Typography variant="body2" align="center">
              {getOpenAnnotationUiButtonNameDependentOnWorkflowState(state)}
            </Typography>
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        TransitionComponent={Transition}
        // className="PdfAnnotationDialog"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Annotation
            </Typography>

            <AnnotationToolsMenu
              handleToolbarCall={(callType) => {
                pdfAnnotationApp.current.toolBarCall(callType);
              }}
            />
            <ValidatePaperAnnotationsButton
              currentRecord={currentRecord}
              useWhiteOutclinedButton={true}
            />
            <TextField
              id={"id-search-pdf-textfield"}
              label="Search PDF"
              variant="outlined"
              autoComplete="off"
              margin="dense"
              onChange={onSearchTextChange}
              onKeyPress={onSearchTextKeyPress}
              InputProps={{
                style: {
                  color: "white",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  marginTop: "2px",
                  color: "white",
                  borderColor: "white",
                },
              }}
            />
            <Button
              size="medium"
              variant="outlined"
              style={{ borderColor: "white", color: "white", marginLeft: 20 }}
              onClick={onPrevResult}
            >
              Prev
            </Button>
            <Button
              size="medium"
              variant="outlined"
              style={{ borderColor: "white", color: "white", marginRight: 80 }}
              onClick={onNextResult}
              styles
            >
              Next
            </Button>

            {showForwardButton && (
              <Button
                disableRipple
                size="medium"
                style={{ color: "white" }}
                onClick={onClickForward}
              >
                Forward
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <PdfAnnotationApp
            ref={pdfAnnotationApp}
            workflowState={state}
            currentRecord={currentRecord}
            eventBusInitialized={(eventBus) => {
              setEventBus(eventBus);
            }}
            toolbarFunctionalityCallType={""}
            // key={updatePdfViewer}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
