import { Box, Button, Grid, Typography } from "@material-ui/core";
import type {
  IHighlight,
  EditHighlightOperation,
} from "../../../libs/react-pdf-highlighter";
import React from "react";
import { AnnotationWriterType } from "../../../model/annotation/AnnotationWriterType";

import EditBaseAnnotationButton from "../buttons/EditBaseAnnotationButton";

/**
 * Button row with four button for the content annotation
 */
export function ContentAnnotationButtonRow(
  removeAnnotation: Function,
  acceptAnnotationByAnnotator: Function,
  duplicateOrEditAnnotation: (
    editHighlightOperation: EditHighlightOperation
  ) => void,
  highlight: IHighlight
) {
  return (
    <Grid container style={{ marginBottom: "-5px", marginTop: "10px" }}>
      <Grid item sm={3}>
        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            color="secondary"
            disableRipple
            size="small"
            disabled={
              highlight.annotationWriterType === AnnotationWriterType.ANNOTATOR
            }
            onClick={() => {
              acceptAnnotationByAnnotator(highlight);
            }}
          >
            <Typography variant="body2" align="center">
              Accept
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Box display="flex">
          <EditBaseAnnotationButton
            highlightIn={highlight}
            duplicateOrEdit={duplicateOrEditAnnotation}
            duplicate={false}
          />
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Box display="flex">
          <EditBaseAnnotationButton
            highlightIn={highlight}
            duplicateOrEdit={duplicateOrEditAnnotation}
            duplicate={true}
          />
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            disableRipple
            size="small"
            onClick={() => {
              removeAnnotation(highlight);
            }}
          >
            <Typography variant="body2" align="center">
              Delete
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
