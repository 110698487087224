import { Box, Button, Grid, Typography } from "@material-ui/core";
import type { UserConfirmationStateHighlight } from "../../../libs/react-pdf-highlighter";
import React from "react";
import { UserConfirmationState } from "../../../model/annotation/UserConfirmationState";

/**
 * Button row for the ICD entries and the registry entries
 */
export function IcdRegistryAnnotationButtonRow(
  markAsDeleted: Function,
  removeSameButThis: Function,
  acceptAnnotation: Function,
  highlight: UserConfirmationStateHighlight
) {
  return (
    <Grid container style={{ marginBottom: "-5px", marginTop: "10px" }}>
      <Grid item sm={2}>
        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            color="secondary"
            disableRipple
            size="small"
            disabled={
              highlight.userConfirmationState ===
              UserConfirmationState.ACCEPTED.name
            }
            onClick={() => {
              acceptAnnotation(highlight);
            }}
          >
            <Typography variant="body2" align="center">
              Accept
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item sm={7}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            disableRipple
            size="small"
            onClick={() => {
              removeSameButThis(highlight);
            }}
          >
            <Typography variant="body2" align="center">
              Dsbl same exc. this
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            disableRipple
            size="small"
            onClick={() => {
              markAsDeleted(highlight);
            }}
          >
            <Typography variant="body2" align="center">
              Disable
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
