import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ActionButtonStyled } from "../buttons/ActionButton";
import { buttonColor } from "../buttons/buttonProperties";
import { ButtonType } from "../buttons/Buttons";
import { alertConfirm } from "../notification/notificationDialogs";

function AcronymsValidation({ currentRecord, saveRecord }) {
  const [editedRecord, setEditedRecord] = React.useState();

  React.useEffect(() => {
    setEditedRecord(jsonCopy(currentRecord));

    return () => {
      setEditedRecord();
    };
  }, [currentRecord]);

  const handleSave = (editedAcro) => {
    let idx = editedRecord.acronymNotFoundAutomatically.findIndex(
      (x) => x.acronym === editedAcro.acronym
    );

    if (idx !== -1) {
      let newRecord = editedRecord;
      newRecord.acronymNotFoundAutomatically[idx].description =
        editedAcro.description;

      setEditedRecord((editedRecord) => {
        return { ...editedRecord, ...newRecord };
      });

      saveRecord(newRecord);
    } else {
      console.log("Element index not found when saving");
    }
  };

  const handleDelete = (acro) => {
    let msg = " Are you sure you want to delete this acronym?";

    alertConfirm("Delete record", msg, handleDeleteConfirmed, acro);
  };

  const handleDeleteConfirmed = (acro) => {
    let idx = editedRecord.acronymNotFoundAutomatically.findIndex(
      (x) => (x.acronym = acro.acronym)
    );
    if (idx !== -1) {
      let newRecord = editedRecord;
      newRecord.acronymNotFoundAutomatically =
        newRecord.acronymNotFoundAutomatically.splice(idx, idx);

      setEditedRecord((editedRecord) => {
        return { ...editedRecord, ...newRecord };
      });

      saveRecord(newRecord);
    } else {
      console.log("Something went wrong when deleting");
    }
  };

  const headerInfo = (editedRecord) => {
    if (editedRecord?.acronymNotFoundAutomatically?.length === 0) {
      return null;
    }
    return (
      <>
        <Box display="flex" justifyContent="center">
          <Chip size="small" label={"Not found acronyms"} variant="outlined" />
        </Box>
      </>
    );
  };

  return (
    <div>
      <List subheader={headerInfo(editedRecord)}>
        {currentRecord &&
          currentRecord.acronymNotFoundAutomatically &&
          currentRecord.acronymNotFoundAutomatically.map((acro, idx) => {
            return (
              <React.Fragment key={idx + acro.acronym}>
                <AcronymItem
                  acronym={acro}
                  paperId={currentRecord.id}
                  handleSave={handleSave}
                  handleDelete={handleDelete}
                />
              </React.Fragment>
            );
          })}
      </List>
    </div>
  );
}

const AcronymItem = ({ acronym, handleSave, handleDelete }) => {
  const [editedAcro, setEditedAcro] = React.useState();
  const [lastAcro, setLastAcro] = React.useState();
  const [disableTextField, setDisableTextField] = React.useState(false);

  React.useEffect(() => {
    if (acronym !== null) {
      setEditedAcro(jsonCopy(acronym));
      setLastAcro(jsonCopy(acronym));
    }
    return () => {
      setEditedAcro();
    };
  }, [acronym]);

  const handleChange = (e) => {
    let newAcro = editedAcro;
    newAcro.description = e.target.value;
    setEditedAcro((editedAcro) => {
      return { ...editedAcro, ...newAcro };
    });
  };

  const onSaveClick = () => {
    setLastAcro((lastAcro) => {
      return { ...lastAcro, ...editedAcro };
    });

    handleSave(editedAcro);
  };

  const onDeleteClick = () => {
    setDisableTextField(true);
    handleDelete(editedAcro);
  };

  if (!editedAcro) return null;

  var deleteAcronymBtn = (<ActionButtonStyled
    text = "Delete"
    captionText = ""
    disabled={editedAcro.description !== lastAcro.description}
    handleClick={onDeleteClick}
    btnType={ButtonType.DANGER}
    size="small"
  />);

  return (
    <ListItem>
      <Box ml={2}>
        <Typography
          style={{
            marginRight: 20,
            maxWidth: 30,
            // color: checked ? "green" : "orangeRed",
          }}
        >
          {acronym.acronym}
        </Typography>
      </Box>
      <Box
        ml={2}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        flexGrow={1}
      >
        <TextField
          // style={{ width: "100%" }}
          id="annotation"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          margin="dense"
          value={editedAcro.description}
          onChange={handleChange}
          error={editedAcro.description !== lastAcro.description}
          disabled={disableTextField}
        />
      </Box>
      <Box ml={2}>
        <Button
          variant="contained"
          disableRipple
          onClick={() => {
            onSaveClick();
          }}
          color={buttonColor.save}
          disabled={editedAcro.description === lastAcro.description}
          size="small"
        >
          <Typography variant="body2" align="center">
            Save
          </Typography>
        </Button>
      </Box>

      <Box ml={2}>{deleteAcronymBtn}</Box>
    </ListItem>
  );
};

function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

export default AcronymsValidation;
