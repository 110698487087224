import {
  Box,
  Button,
  Checkbox,
  Chip,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import annotationApi from "../../../api/annotationApi";
import { UserConfirmationState } from "../../../model/annotation/UserConfirmationState";
import { WorkflowStates } from "../../../model/WorkflowStates";
import { buttonColor } from "../../subcomponents/buttons/buttonProperties";
import { successSnackbarOptions } from "../../subcomponents/notification/notifications";
import CollapsablePdfViewer from "../../subcomponents/pdf/CollapsablePdfViewer";
import { CollapsableField } from "./subcomponents/CollapsableField";

export const VariableValidation = ({
  state,
  extractedAnnotations,
  currentRecord,
  handleValidationOk,
  validationOk,
}) => {
  extractedAnnotations = extractedAnnotations.sort((a, b) => {
    return a.annotation.annotationText.localeCompare(
      b.annotation.annotationText
    );
  });

  const headerInfo = (
    <Box display="flex" justifyContent="center">
      <Chip
        style={{ color: validationOk === 0 ? "green" : "orangeRed" }}
        size="small"
        label={
          validationOk !== 0
            ? `${validationOk} variables left to validate`
            : "All variables validated, you can move forward."
        }
        variant="outlined"
      />
    </Box>
  );

  return (
    <div>
      {currentRecord && (
        <CollapsableField currentRecord={currentRecord} field={"title"} />
      )}
      {currentRecord && (
        <CollapsableField
          currentRecord={currentRecord}
          field={"abstractText"}
        />
      )}
      <List subheader={validationOk >= 0 && headerInfo}>
        {extractedAnnotations.map((anno, idx) => {
          return (
            <React.Fragment key={idx + anno.annotation.annotationText}>
              <AnnoItem
                anno={anno}
                paperId={currentRecord.id}
                handleValidation={handleValidationOk}
                disableCheck={
                  currentRecord.workflowState !==
                  WorkflowStates.EXTRACTION_VALIDATION_VARIABLES.state
                }
              />
            </React.Fragment>
          );
        })}
      </List>
      {state && currentRecord && (
        <CollapsablePdfViewer state={state} currentRecord={currentRecord} />
      )}
    </div>
  );
};

const AnnoItem = ({ anno, paperId, handleValidation, disableCheck }) => {
  const [lastAnno, setLastAnno] = React.useState();
  const [editedAnno, setEditedAnno] = React.useState();
  const [modified, setModified] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [imagesPaths, setImagesPaths] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (anno.annotation !== null) {
      setEditedAnno(jsonCopy(anno));
      setLastAnno(jsonCopy(anno));
      if (anno.annotation.filePathsForScreenshots !== null) {
        anno.annotation.filePathsForScreenshots.forEach((anno) => {
          let parts = anno.split("/");
          annotationApi
            .getExtractedAnnotationImagesForPaper(parts[0], parts[1])
            .then((response) => {
              setImagesPaths((prev) => [...prev, response]);
            });
        });
      } else {
        console.log("empty filePathsForScreenshots");
      }
      // }
    }

    setChecked(
      anno.userConfirmationState === UserConfirmationState.ACCEPTED.name
    );

    return () => {
      setImagesPaths([]);
      setModified(false);
      setEditedAnno();
      setLastAnno();
      setChecked(false);
    };
  }, [anno]);

  const handleChange = (e) => {
    let modified =
      e.target.value !== lastAnno.annotation.annotatedContent ? true : false;

    setModified(modified);

    let newAnno = editedAnno;
    newAnno.annotation.annotatedContent = e.target.value;
    setEditedAnno((editedAnno) => {
      return { ...editedAnno, ...newAnno };
    });
  };

  const onSaveClick = () => {
    annotationApi
      .updateExtractedAnnotationToPaper(editedAnno, paperId)
      .then((response) => {
        if (response.statusText === "OK") {
          enqueueSnackbar(
            "Annotation successfully modified",
            successSnackbarOptions
          );
        }
      });
    setLastAnno(editedAnno);
    setModified(false);
  };

  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);

    let status;

    if (event.target.checked) {
      status = UserConfirmationState.ACCEPTED.name;
    } else {
      status = UserConfirmationState.DEFAULT.name;
    }

    let newAnno = editedAnno;
    newAnno.userConfirmationState = status;

    annotationApi
      .updateExtractedAnnotationToPaper(newAnno, paperId)
      .then((response) => {
        if (response.statusText === "OK") {
          enqueueSnackbar(
            "Annotation successfully modified",
            successSnackbarOptions
          );
        }
      });

    setEditedAnno((editedAnno) => {
      return { ...editedAnno, ...newAnno };
    });
    handleValidation(event.target.checked);
  };

  if (!editedAnno) return null;

  return (
    <div>
      <ListItem>
        <Box ml={2}>
          <Typography
            style={{
              marginRight: 130,
              maxWidth: 30,
              color: checked ? "green" : "orangeRed",
            }}
          >
            {anno.annotation.annotationText}
          </Typography>
        </Box>
        <Box
          ml={2}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flexGrow={1}
        >
          <TextField
            // style={{ width: "100%" }}
            id="annotation"
            variant="outlined"
            size="small"
            fullWidth
            // multiline
            margin="dense"
            value={editedAnno.annotation.annotatedContent}
            onChange={handleChange}
            // style={{ minWidth: 600 }}
            error={modified}
            disabled={checked}
          />
        </Box>
        <Box ml={2}>
          <Button
            variant="contained"
            disableRipple
            onClick={() => {
              onSaveClick();
            }}
            color={buttonColor.save}
            disabled={!modified}
            size="small"
          >
            <Typography variant="body2" align="center">
              Save
            </Typography>
          </Button>
        </Box>
        <Box ml={2} display="flex">
          <Checkbox
            checked={checked}
            onChange={handleChangeCheckBox}
            inputProps={{ "aria-label": "primary checkbox" }}
            disabled={modified || disableCheck}
          />
        </Box>
      </ListItem>
      <List>
        <Box
          mt={-2}
          mb={1}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
        >
          {imagesPaths.length > 0 ? (
            imagesPaths.map((item, idx) => {
              return (
                <React.Fragment key={idx + item}>
                  <Box>
                    <Tooltip
                      enterDelay={500}
                      placement="bottom-start"
                      title={
                        <>
                          <img alt="anno" src={item} />
                        </>
                      }
                    >
                      <ListItem>
                        <Box
                          src={item}
                          component="img"
                          style={{
                            maxHeight: "35px",
                            maxWidth: "800px",
                          }}
                          alt="anno"
                        />
                      </ListItem>
                    </Tooltip>
                  </Box>
                </React.Fragment>
              );
            })
          ) : (
            <span>No images available</span>
          )}
        </Box>
      </List>
    </div>
  );
};

function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

export const totalValidated = (extractedAnnotations) => {
  let ok = 0;
  extractedAnnotations.forEach((element) => {
    if (
      [
        UserConfirmationState.ACCEPTED.name,
        UserConfirmationState.CHANGED.name,
      ].includes(element.userConfirmationState)
    ) {
      ok += 1;
    }
  });

  return ok;
};

// export default { AnnotationsValidation, totalValidated };
