import { Box } from "@material-ui/core";
import React from "react";
import { ActionButton } from "../buttons/ActionButton";
import { buttonColor } from "../buttons/buttonProperties";

function TopActions({ handleRefreshPage }) {
  const refreshButton = (<ActionButton
    text="Refresh"
    captionText={null}
    disabled={false}
    handleClick={handleRefreshPage}
    color={buttonColor.refresh}
  />)
    
  return (
    <div>
      <Box mr={4} width={50}>
        {refreshButton}
      </Box>
    </div>
  );
}

export default TopActions;
