import { Box } from "@material-ui/core";
import React, { Component } from "react";
import {
  areBasicAndOutcomeMenuItemsVisibleOnAnnotationPane,
  areIcdMenuItemsVisibleOnAnnotationPane,
  areRegistryMenuItemsVisibleOnAnnotationPane,
} from "../../../components/annotation/utils/filterUtils";
import "../style/Tip.css";
import { BaseAnnotationTip } from "./BaseAnnotationTip";
import { OutcomeAnnotationTip } from "./OutcomeAnnotationTip";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import { OutcomeAnnotation } from "../../../model/annotation/outcome/OutcomeAnnotation";

enum AnnotationTypeTip {
  BASE,
  OUTCOME,
  ICD10,
  ICD11,
}

interface State {
  compact: boolean;
  annotationType: AnnotationTypeTip;
}

interface Props {
  filterState: string;
  onConfirm: (
    comment: { text: string; count: string },
    chapter: string,
    annotationType: string,
    outcomeAnnotation: OutcomeAnnotation | null
  ) => void;
  onOpen: () => void;
  onUpdate?: () => void;
  lastOutcomeAnnotation: OutcomeAnnotation | null;
}

export class Tip extends Component<Props, State> {
  state: State = {
    compact: true,
    annotationType: AnnotationType.BASE,
  };

  // for TipContainer
  componentDidUpdate(nextProps: Props, nextState: State) {
    const { onUpdate } = this.props;

    if (onUpdate && this.state.compact !== nextState.compact) {
      onUpdate();
    }
  }

  handleOnClick = (annotationType: AnnotationTypeTip) => {
    this.setState({
      compact: false,
      annotationType: annotationType,
    });
  };
  handleOnChangeSelect = (e: any) => {
    console.log(e.target.value);
  };

  createMenuItems = (): React.ReactNode => {
    const { onOpen, onConfirm } = this.props;
    return (
      <div>
        {areRegistryMenuItemsVisibleOnAnnotationPane(this.props.filterState) ? (
          <div>
            <div
              className="Tip__compact"
              onClick={() => {
                onConfirm(
                  { text: "Registry", count: "" },
                  "10",
                  AnnotationType.REGISTRY.type,
                  null
                );
              }}
            >
              Registry entry
            </div>
          </div>
        ) : null}
        {areIcdMenuItemsVisibleOnAnnotationPane(this.props.filterState) ? (
          <div>
            <div
              className="Tip__compact"
              onClick={() => {
                onConfirm(
                  { text: "ICD 10", count: "" },
                  "11",
                  AnnotationType.ICD.type,
                  null
                );
              }}
            >
              ICD 10
            </div>
            <div
              className="Tip__compact"
              onClick={() => {
                onConfirm(
                  { text: "ICD 11", count: "" },
                  "11",
                  AnnotationType.ICD.type,
                  null
                );
              }}
            >
              ICD 11
            </div>
          </div>
        ) : null}
        {areBasicAndOutcomeMenuItemsVisibleOnAnnotationPane(
          this.props.filterState
        ) ? (
          <div>
            <div
              className="Tip__compact"
              onClick={() => {
                this.handleOnClick(AnnotationTypeTip.OUTCOME);
                onOpen();
              }}
            >
              Add outcome
            </div>
            <div
              className="Tip__compact"
              onClick={() => {
                this.handleOnClick(AnnotationTypeTip.BASE);
                onOpen();
              }}
            >
              Add annotation
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { onConfirm } = this.props;
    const { compact, annotationType } = this.state;

    return (
      <Box className="Tip">
        {compact ? (
          this.createMenuItems()
        ) : annotationType === AnnotationTypeTip.BASE ? (
          <Box boxShadow={3}>
            <BaseAnnotationTip onConfirm={onConfirm} />
          </Box>
        ) : (
          <Box boxShadow={3}>
            <OutcomeAnnotationTip
              onConfirm={onConfirm}
              lastOutcomeAnnotation={this.props.lastOutcomeAnnotation}
            />
          </Box>
        )}
      </Box>
    );
  }
}

export default Tip;
