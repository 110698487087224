import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { UserConfirmationStateHighlight } from "../../../libs/react-pdf-highlighter";
import { IcdRegistryAnnotationButtonRow } from "./IcdRegistryAnnotationButtonRow";

/**
 * Content of the ICD and registry annotations
 */
export function IcdOrRegistryAnnotation(
  highlight: UserConfirmationStateHighlight,
  disableAnnotation: Function,
  removeSameButThis: Function,
  acceptAnnotation: Function
): JSX.Element {
  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: "-20px" }}>
        <Grid item xs={9}>
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            {"(" + highlight.chapter + ") " + highlight.comment.text}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          Page {highlight.position.pageNumber}
        </Grid>
      </Grid>
      {highlight.content.text ? (
        <blockquote style={{ marginTop: "0.3rem" }}>
          {`${highlight.content.text.slice(0, 90).trim()}`}
        </blockquote>
      ) : null}
      {highlight.content.image ? (
        <div className="highlight__image" style={{ marginTop: "0.5rem" }}>
          <img src={highlight.content.image} alt={"Screenshot"} />
        </div>
      ) : null}
      {IcdRegistryAnnotationButtonRow(
        disableAnnotation,
        removeSameButThis,
        acceptAnnotation,
        highlight
      )}
    </div>
  );
}
