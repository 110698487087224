import { Box, TextField, Typography } from "@material-ui/core";
import React from "react";
import FieldLink from "../../../subcomponents/record/FieldLink";

export const PublicationReferenceComponent = ({
  content,
  name,
  //   onSaveItem,
  //   contentType = "",
  disableTextField = true,
  handleDisableCheckbox = null,
}) => {
  const [editedContent, setEditedContent] = React.useState();
  const [lastContent, setLastContent] = React.useState();
  // const [disableTextField, setDisableTextField] = React.useState(false);

  React.useEffect(() => {
    if (content !== null) {
      setEditedContent(content);
      setLastContent(content);
    }
    return () => {
      setEditedContent();
      setLastContent();
    };
  }, [content]);

  //   const handleSaveClick = () => {
  //     setLastContent(editedContent);

  //     // onSaveItem(editedContent);
  //   };

  const handleChange = (e) => {
    setEditedContent(e.target.value);
  };

  if (!editedContent) return null;

  const textFieldError = editedContent !== lastContent;
  let fieldName; // = name === "pmid" ? "pubmedId" : "doi";
  if (name === "pmid") {
    fieldName = "pubmedId";
  } else if (name === "doi") {
    fieldName = "doi";
  }

  return (
    // <ListItem>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box
        style={{
          marginRight: 20,
          minWidth: 100,
        }}
        display="flex"
        flexDirection="row"
      >
        <Typography>{name}</Typography>
      </Box>

      <Box
        ml={2}
        width={400}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <TextField
          id="annotation"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          margin="dense"
          value={editedContent}
          onChange={handleChange}
          error={textFieldError}
          disabled={disableTextField}
        />
      </Box>
      <Box ml={2}>
        <FieldLink field={fieldName} value={editedContent} />
      </Box>
      {/* <Box ml={2}>
        <Button
          variant="contained"
          disableRipple
          onClick={handleSaveClick}
          color={buttonColor.save}
          disabled={editedContent === lastContent}
          size="small"
        >
          <Typography variant="body2" align="center">
            Save
          </Typography>
        </Button>
      </Box> */}
    </Box>
  );
};
