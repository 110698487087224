import { Box, Button, Typography } from "@material-ui/core";
import PubSub from "pubsub-js";
import React, { useEffect } from "react";
import { getCustomButton } from "./Buttons";
import { PubSubMessage } from "../../../utils/communication/PubSubTypes";

export function ActionButton({
  text,
  captionText,
  disabled,
  handleClick,
  color,
  variant = "contained",
  fullWidth = false,
  size = "medium",
}
) {
  const [disabledByEvent, setDisabledByEvent] = React.useState(false);

  let disableEventSubscriber = null;
  // The disable event is of type PubSubBooleanType.
  var mySubscriber = function (msg, data) {
    setDisabledByEvent(data.value);
  };
  disableEventSubscriber = PubSub.subscribe(PubSubMessage.DISABLE_ACTION_EVENT, mySubscriber);
  useEffect(() => {
    return () => {
      if (disableEventSubscriber != null) {
        PubSub.unsubscribe(disableEventSubscriber);
      }
    };
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      // alignContent="flex-start"
      alignItems="center"
    >
      <Button
        variant={variant}
        disableRipple
        color={color}
        onClick={() => {
          handleClick();
        }}
        size={size}
        disabled={disabled || disabledByEvent}
        fullWidth={fullWidth}
      >
        {
          <Typography variant="body2" align="center">
            {text}
          </Typography>
        }
      </Button>
      {captionText && (
        <Typography variant="caption" color="textSecondary" align="center">
          {captionText}
        </Typography>
      )}
    </Box>
  );
}

export function ActionButtonStyled({
  text,
  captionText,
  disabled,
  handleClick,
  btnType,
  size = "medium"
}) {
  const CustomButton = getCustomButton(btnType);
  const [disabledByEvent, setDisabledByEvent] = React.useState(false);
  

  let disableEventSubscriber = null;
  // The disable event is of type PubSubBooleanType.
  var mySubscriber = function (msg, data) {
    setDisabledByEvent(data.value);
  };
  disableEventSubscriber = PubSub.subscribe(PubSubMessage.DISABLE_ACTION_EVENT, mySubscriber);
  useEffect(() => {
    return () => {
      if (disableEventSubscriber != null) {
        PubSub.unsubscribe(disableEventSubscriber);
      }
    };
  });

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start">
      <CustomButton
        disableRipple
        onClick={() => {
          handleClick();
        }}
        size={size}
        disabled={disabled || disabledByEvent}
      >
        {
          <Typography variant="body2" display="block" align="center">
            {text}
          </Typography>
        }
      </CustomButton>
      {captionText && (
        <Typography variant="caption" color="textSecondary">
          {captionText}
        </Typography>
      )}
    </Box>
  );
}
