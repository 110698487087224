import React from "react";
import {
  Button,
  Grid,
  PropTypes,
  TextField,
  Typography,
} from "@material-ui/core";
import { buttonColor } from "../../../../subcomponents/buttons/buttonProperties";
import { PublicationContent } from "../../../../../model/publicationdata/PublicationData";

interface OutcomeResultContentComponentProps {
  publicationContent: PublicationContent;
  caption: string;
  disableTextFields: boolean;
  saveChanges: Function;
}

/**
 * simple outcome property of a result in the outcome like spec, measure or measure type
 */
export function OutcomeResultContentComponent({
  publicationContent,
  caption,
  disableTextFields,
  saveChanges,
}: OutcomeResultContentComponentProps) {
  const [editedContent, setEditedContent] = React.useState(publicationContent);

  if (publicationContent === null) {
    return null;
  }

  const handleChange = (e: any) => {
    let newContent = editedContent;
    newContent.content = e.target.value;
    setEditedContent((editedContent) => {
      return { ...editedContent, ...newContent };
    });
  };

  const saveChangesLocally = (e: any) => {
    const newContent = editedContent;
    saveChanges(newContent);
  };

  const fieldContent =
    editedContent !== null ? editedContent : publicationContent;

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        style={{ minWidth: 800 }}
      >
        <Grid item xs={3}>
          <Typography>{caption}</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            id={"id-" + publicationContent.variableName}
            variant="outlined"
            size="small"
            fullWidth
            multiline
            margin="dense"
            value={fieldContent.content}
            onChange={handleChange}
            disabled={disableTextFields}
          />
          {/* later:
              error={textFieldError} */}
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            disableRipple
            onClick={saveChangesLocally}
            color={buttonColor.save as PropTypes.Color}
            disabled={disableTextFields}
            size="small"
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            {publicationContent.content !== publicationContent.contentOriginal
              ? "Orig. value: " + publicationContent.contentOriginal
              : null}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OutcomeResultContentComponent;
