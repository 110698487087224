import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Box } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import Draggable from "react-draggable";
import { UiPropertiesTable } from "../tables/UiPropertiesTable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function EditHighlightedKeywords() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        variant="text"
        style={{ backgroundColor: "transparent", font: "menu" }}
        disableRipple
        onClick={handleClickOpen}
        disabled={false}
        size="small"
      >
        <Typography>Edit Keywords</Typography>
      </Button>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        PaperComponent={PaperComponent}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog">
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              edge="start"
              color="primary"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Typography variant="body1">Highlighted Keywords</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <UiPropertiesTable />
        </DialogContent>
      </Dialog>
    </div>
  );
}
