import * as React from "react";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AddComment } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ToolsMenuCallType } from "../../../model/annotation/view/ToolsMenuCallType";

interface AnnotationToolsMenuProps {
  handleToolbarCall: (toolsMenuCallType: ToolsMenuCallType) => void;
}

export default function AnnotationToolsMenu({
  handleToolbarCall,
}: AnnotationToolsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const createEmptyAnnotation = () => {
    handleClose();
    handleToolbarCall(ToolsMenuCallType.EMPTY_ANNOTATION);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        size="medium"
        variant="outlined"
        style={{
          borderColor: "white",
          color: "white",
          marginRight: 40,
          cursor: "default",
        }}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        Tools
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={createEmptyAnnotation}>
          <ListItemIcon>
            <AddComment />
          </ListItemIcon>
          <ListItemText>Create empty annotation</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
