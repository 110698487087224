// import { Box, Typography } from "@material-ui/core";
import React from "react";
// import publicationDataApi from "../../../api/publicationDataApi";
// import { OutcomeContent, OutcomeSectionContent, PublicationData } from "../../../model/publicationdata/PublicationData";

import { Box, Checkbox, Grid, Typography } from "@material-ui/core";
import {
  GroupResultContent,
  OutcomeContent,
  OutcomeSectionContent,
  OutcomeSectionResultContent,
  ResultTimePointContent,
} from "../../../../model/publicationdata/PublicationData";
import ValidationWarningBox from "./ValidationWarningBox";
import {
  getConfStateByWorkflowState,
  setConfStateByWorkflowState,
} from "../../../../utils/validation/confirmationByStateUtils";
import { UserConfirmationState } from "../../../../model/annotation/UserConfirmationState";
import OutcomeSectionContentVariableComponent from "./outcomes/OutcomeNameContentComponent";
import OutcomePropertyContentComponent from "./outcomes/OutcomePropertyContentComponent";
import OutcomeSectionResultTimeContentsComponent from "./outcomes/OutcomeSectionResultTimeContentsComponent";
import { overWriteCheckboxDisable } from "../../../../utils/validation/ValidationWorkflowStateUtils";

interface OutcomeSectionsComponentProps {
  outcomeContent: OutcomeContent;
  currentWorkflowState: string;
  type: string; // primary/secondary
  updateOutomeContentInPublicationData: (
    outcomeContent: OutcomeContent,
    type: string
  ) => void;
  showChapter: boolean;
}

export function OutcomeSectionsComponent({
  outcomeContent,
  currentWorkflowState,
  type,
  updateOutomeContentInPublicationData,
  showChapter,
}: OutcomeSectionsComponentProps): any {
  const chapterDefault = outcomeContent.chapter + ". " + type + " outcome";
  const { chapter, validationWarnings } = outcomeContent;
  const baseMarginLeft = 2;

  return (
    <div>
      <Box mb={1}>
        {showChapter ? (
          <Box mb={2}>
            <Typography variant="h5">{chapterDefault}</Typography>
          </Box>
        ) : null}
        {validationWarningBox(chapter, validationWarnings, baseMarginLeft)}
      </Box>
      <OutcomeContentComponent
        outcomeContent={outcomeContent}
        currentWorkflowState={currentWorkflowState}
        updateOutomeContentInPublicationData={
          updateOutomeContentInPublicationData
        }
        type={type}
      />
    </div>
  );
}

interface OutcomeContentProps {
  outcomeContent: OutcomeContent;
  currentWorkflowState: string;
  updateOutomeContentInPublicationData: (
    outcomeContent: OutcomeContent,
    type: string
  ) => void;
  type: string;
}

interface OutcomeSectionProps {
  section: OutcomeSectionContent;
  marginLeft: number;
  currentWorkflowState: string;
  updateContentWithSection: (section: OutcomeSectionContent) => void;
  type: string;
}

const OutcomeSectionComponent = ({
  section,
  marginLeft,
  currentWorkflowState,
  updateContentWithSection,
  type,
}: OutcomeSectionProps) => {
  const _checked =
    getConfStateByWorkflowState(
      section.userConfirmationByState,
      currentWorkflowState
    ) === UserConfirmationState.ACCEPTED.name;

  const [checked, setChecked] = React.useState(_checked);
  const [disableTextFields, setDisableTextFields] = React.useState(_checked);

  const handleChangeCheckBox = (event: any) => {
    setChecked(event.target.checked);
    setDisableTextFields(event.target.checked);

    let check;

    if (event.target.checked) {
      check = UserConfirmationState.ACCEPTED.name;
    } else {
      check = UserConfirmationState.DEFAULT.name;
    }

    console.log(check);
    console.log(currentWorkflowState);

    const newSection = section;
    setConfStateByWorkflowState(
      newSection.userConfirmationByState,
      currentWorkflowState,
      check
    );
    updateContentWithSection(newSection);
  };

  const overwriteCheckboxDisable =
    overWriteCheckboxDisable(currentWorkflowState);
  const disableCheckbox =
    isAtleastOneVariableDifferent(section) &&
    !checked &&
    !overwriteCheckboxDisable;

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginLeft={marginLeft}
      >
        <Box flexDirection="column" mb={1} mt={4}>
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{ width: 400, maxWidth: 500 }}
          >
            <Grid item xs={7}>
              <Typography variant="h6">
                {type + " outcome " + section.chapter.substring(4)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box ml={2}>Validate</Box>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={checked}
                onChange={handleChangeCheckBox}
                disabled={disableCheckbox}
                // disabled = {content.userConfirmationByState.confirmationRELEASE_VALIDATION}
              />
            </Grid>
          </Grid>

          <Box ml={marginLeft + 2}>
            {validationWarningBox(
              section.chapter,
              section.validationWarnings,
              marginLeft
            )}
            {outcomeMainlayer(
              section,
              disableTextFields,
              updateContentWithSection
            )}
          </Box>
        </Box>
      </Box>
      {section.children !== null
        ? section.children.map((outcomeSection) => {
            return (
              <OutcomeSectionComponent
                section={outcomeSection}
                marginLeft={marginLeft + 2}
                currentWorkflowState={currentWorkflowState}
                updateContentWithSection={updateContentWithSection}
                type={type}
              />
            );
          })
        : null}
    </div>
  );
  // for each section in the section
  // call renderSection
};

const outcomeMainlayer = (
  section: OutcomeSectionContent,
  disableTextFields: boolean,
  updateContentWithSection: Function
) => {
  var results: any = [];
  if (section.results !== null) {
    for (var i: number = 0; i < section.results.length; i++) {
      const outcomeSectionResultContent: OutcomeSectionResultContent =
        section.results[i];
      if (outcomeSectionResultContent !== null) {
        results.push(
          outcomeResultlayer(
            section,
            outcomeSectionResultContent,
            disableTextFields,
            updateContentWithSection
          )
        );
      }
    }
  }

  return (
    <div>
      {section.name == null ? (
        renderMissingElement("Outcome name")
      ) : (
        <OutcomeSectionContentVariableComponent
          section={section}
          caption="Name"
          publicationContent={section.name}
          disableTextFields={disableTextFields}
          updateContentWithSection={updateContentWithSection}
        />
      )}
      {section.timeFrame == null ? (
        renderMissingElement("time frame")
      ) : (
        <OutcomeSectionContentVariableComponent
          section={section}
          caption="Time frame"
          publicationContent={section.timeFrame}
          disableTextFields={disableTextFields}
          updateContentWithSection={updateContentWithSection}
        />
      )}
      {results}
    </div>
  );
};

const outcomeResultlayer = (
  section: OutcomeSectionContent,
  result: OutcomeSectionResultContent,
  disableTextFields: boolean,
  updateContentWithSection: Function
) => {
  return (
    <div>
      <Box flexDirection="column" mb={1} mt={4}>
        <Typography variant="subtitle2">
          {"Result: " + result.resultId}
        </Typography>
      </Box>
      {validationWarningBox(section.chapter, section.validationWarnings, 0)}
      {result.spec == null ? null : (
        <OutcomePropertyContentComponent
          section={section}
          result={result}
          caption="spec"
          publicationContent={result.spec}
          disableTextFields={disableTextFields}
          updateContentWithSection={updateContentWithSection}
        />
      )}
      {result.measure == null ? (
        renderMissingElement("measure")
      ) : (
        <OutcomePropertyContentComponent
          section={section}
          result={result}
          caption="measure"
          publicationContent={result.measure}
          disableTextFields={disableTextFields}
          updateContentWithSection={updateContentWithSection}
        />
      )}
      {result.measureType == null ? null : (
        <OutcomePropertyContentComponent
          section={section}
          result={result}
          caption="measure type"
          publicationContent={result.measureType}
          disableTextFields={disableTextFields}
          updateContentWithSection={updateContentWithSection}
        />
      )}
      {result.resultTimePointContent == null ? (
        renderMissingElement("Results for groups")
      ) : (
        <OutcomeSectionResultTimeContentsComponent
          section={section}
          result={result}
          disableTextFields={disableTextFields}
          updateContentWithSection={updateContentWithSection}
        />
      )}
    </div>
  );
};

const renderMissingElement = (variableName: string) => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        style={{ minWidth: 300 }}
      >
        <Grid item xs={3}>
          <Typography>Missing variable:</Typography>
        </Grid>
        <Grid item xs={9} justifyContent="flex-start">
          <Typography style={{ color: "red" }}>{variableName}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

/**
 * Base update function of recursive update of the outcome sections
 */
function updateStateOutcomeContent(
  content: OutcomeContent,
  sectionUpdate: OutcomeSectionContent
): OutcomeContent {
  let updated: boolean = false;
  if (content.children !== null) {
    for (let i = 0; i < content.children.length; i++) {
      if (content.children[i].chapter === sectionUpdate.chapter) {
        content.children[i] = sectionUpdate;
        return content;
      }

      updated = updateStateOutcomeSection(content.children[i], sectionUpdate);
      if (updated) {
        return content;
      }
    }
  }
  return content;
}

/**
 * Update section recursively if a chapter of the children of the section fits the sectionUpdate
 */
function updateStateOutcomeSection(
  section: OutcomeSectionContent,
  sectionUpdate: OutcomeSectionContent
): boolean {
  let updated: boolean = false;
  if (section.children !== null) {
    for (let i = 0; i < section.children.length; i++) {
      if (section.children[i].chapter === sectionUpdate.chapter) {
        section.children[i] = sectionUpdate;
        return true;
      }

      updated = updateStateOutcomeSection(section.children[i], sectionUpdate);
      if (updated) {
        return true;
      }
    }
  }
  return false;
}

// recursive creation of the outcomes
const OutcomeContentComponent = ({
  outcomeContent,
  currentWorkflowState,
  updateOutomeContentInPublicationData,
  type,
}: OutcomeContentProps) => {
  const updateContentWithSection = (section: OutcomeSectionContent) => {
    updateStateOutcomeContent(outcomeContent, section);
    updateOutomeContentInPublicationData(outcomeContent, type);
  };

  if (outcomeContent === null || outcomeContent.children === null) {
    return (
      <Box ml={2}>
        <Typography>No content in this outcome</Typography>
      </Box>
    );
  } else {
    return (
      <div>
        {outcomeContent.children.map((outcomeSection) => {
          return (
            <OutcomeSectionComponent
              section={outcomeSection}
              marginLeft={2}
              currentWorkflowState={currentWorkflowState}
              updateContentWithSection={updateContentWithSection}
              type={type}
            />
          );
        })}
      </div>
    );
  }
};

const notNull = (content: any) => {
  return content !== null;
};

const isAtleastOneVariableOnResultSectionDifferent = (
  result: OutcomeSectionResultContent
) => {
  if (result.resultTimePointContent !== null) {
    for (let i = 0; i < result.resultTimePointContent.length; i++) {
      const timePointContent: ResultTimePointContent =
        result.resultTimePointContent[i];
      if (
        notNull(timePointContent.time) &&
        timePointContent.time.content !== timePointContent.time.contentOriginal
      ) {
        return true;
      }
      if (timePointContent.groupResultContent !== null) {
        for (let j = 0; j < timePointContent.groupResultContent.length; j++) {
          const groupContent: GroupResultContent =
            timePointContent.groupResultContent[j];
          if (
            notNull(groupContent.result) &&
            groupContent.result.content !== groupContent.result.contentOriginal
          ) {
            return true;
          } else if (
            notNull(groupContent.sd) &&
            groupContent.sd.content !== groupContent.sd.contentOriginal
          ) {
            return true;
          } else if (
            notNull(groupContent.iqrlow) &&
            groupContent.iqrlow.content !== groupContent.iqrlow.contentOriginal
          ) {
            return true;
          } else if (
            notNull(groupContent.iqrlow) &&
            groupContent.iqrup.content !== groupContent.iqrup.contentOriginal
          ) {
            return true;
          } else if (
            notNull(groupContent.ci95low) &&
            groupContent.ci95low.content !==
              groupContent.ci95low.contentOriginal
          ) {
            return true;
          } else if (
            notNull(groupContent.ci95up) &&
            groupContent.ci95up.content !== groupContent.ci95up.contentOriginal
          ) {
            return true;
          } else if (
            notNull(groupContent.pvalue) &&
            groupContent.pvalue.content !== groupContent.pvalue.contentOriginal
          ) {
            return true;
          }
        }
      }
    }
  }
  if (
    notNull(result.spec) &&
    result.spec.content !== result.spec.contentOriginal
  ) {
    return true;
  } else if (
    notNull(result.measure) &&
    result.measure.content !== result.measure.contentOriginal
  ) {
    return true;
  } else if (
    notNull(result.measureType) &&
    result.measureType.content !== result.measureType.contentOriginal
  ) {
    return true;
  }
};

const isAtleastOneVariableDifferent = (section: OutcomeSectionContent) => {
  if (
    notNull(section.name) &&
    section.name.content !== section.name.contentOriginal
  ) {
    return true;
  } else if (notNull(section.results)) {
    for (let i = 0; i < section.results.length; i++) {
      if (isAtleastOneVariableOnResultSectionDifferent(section.results[i])) {
        return true;
      }
    }
  }

  return false;
};

const validationWarningBox = (
  chapter: string,
  warnings: Array<string>,
  accordionMarginLeft: number
) => {
  return (
    <ValidationWarningBox
      chapter={chapter}
      warnings={warnings}
      accordionMarginLeft={accordionMarginLeft}
    />
  );
};

export default OutcomeSectionsComponent;
