import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Chip,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopy";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { successSnackbarOptions } from "../notification/notifications";
import { getIdAndIdx } from "../../views/fetchData";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function AreaTopicSelect({
  areas,
  topics,
  initialAreaId,
  initialTopicId,
  handleChangeArea,
  handleChangeTopic,
  responsible,
}) {
  const classes = useStyles();
  const [selectedAreaId, setSelectedAreaId] = useState(initialAreaId);
  const [selectedTopicId, setSelectedTopicId] = useState(initialTopicId);

  const { enqueueSnackbar } = useSnackbar();

  const onChangeArea = (e) => {
    setSelectedAreaId(e.target.value);
    handleChangeArea(e.target.value);
  };

  const onChangeTopic = (e) => {
    setSelectedTopicId(e.target.value);
    handleChangeTopic(e.target.value);
  };

  const SelectArea = createAreaSelector(
    areas,
    selectedAreaId,
    onChangeArea,
    classes
  );
  const SelectTopic = createTopicSelector(
    topics,
    selectedTopicId,
    onChangeTopic,
    classes
  );

  const responsibleChip = (
    <Box ml={3} mt={3}>
      <Chip size="small" color="primary" label={responsible} />
    </Box>
  );

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        {SelectArea}
        {copyToClipBoard(areas, selectedAreaId, "name", enqueueSnackbar)}
        {responsible && responsibleChip}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        {SelectTopic}
        {copyToClipBoard(topics, selectedTopicId, "title", enqueueSnackbar)}
      </Box>
    </React.Fragment>
  );
}

const copyToClipBoard = (array, fieldId, field, enqueueSnackbar) => {
  let idx = array.findIndex(({ id }) => id === fieldId);
  return (
    <IconButton
      onClick={() => {
        navigator.clipboard.writeText(array[idx][field]).then(
          () => {
            enqueueSnackbar("copied!", successSnackbarOptions);
          },
          () => {
            console.log("error with clipboard!");
          }
        );
      }}
      edge="start"
      disableRipple
      disableFocusRipple
      size="small"
    >
      <FileCopyOutlinedIcon viewBox="-5 -5 40 15"></FileCopyOutlinedIcon>
    </IconButton>
  );
};

function createAreaSelector(areas, selectedAreaId, handleChangeArea, classes) {
  let areaItems = [];

  if (areas.length > 0) {
    areaItems = areas.map((item) => {
      return (
        <MenuItem dense key={item.id.toString()} value={item.id}>
          {item.name}
        </MenuItem>
      );
    });
  } else {
    areaItems.push(
      <MenuItem key={0} value={0}>
        No area items
      </MenuItem>
    );
  }

  const SelectArea = (
    <FormControl className={classes.formControl} margin="dense">
      <InputLabel id="in_select_area">Area</InputLabel>
      <Select
        labelId="select_area"
        id="select_area"
        value={selectedAreaId}
        onChange={handleChangeArea}
        style={{ paddingLeft: 10 }}
      >
        {areaItems}
      </Select>
    </FormControl>
  );

  return SelectArea;
}

function createTopicSelector(
  topics,
  selectedTopicId,
  handleChangeTopic,
  classes
) {
  let topicItems = [];

  let idAndIdx = getIdAndIdx(topics, selectedTopicId);

  if (topics.length > 0) {
    topicItems = topics.map((item) => {
      return (
        <MenuItem dense key={item.id.toString()} value={item.id}>
          {item.title}
        </MenuItem>
      );
    });
  } else {
    topicItems.push(
      <MenuItem key={0} value={0}>
        No topic items
      </MenuItem>
    );
  }

  const SelectTopic = (
    <FormControl className={classes.formControl} margin="dense">
      <InputLabel id="in_select_topic">Topic</InputLabel>
      <Select
        // variant="outlined"
        labelId="select_topic"
        id="select_topic"
        value={idAndIdx.id}
        onChange={handleChangeTopic}
        style={{ paddingLeft: 10 }}
      >
        {topicItems}
      </Select>
    </FormControl>
  );

  return SelectTopic;
}

export default AreaTopicSelect;
