import { Box, Grid, makeStyles } from "@material-ui/core";
// import { Box } from "@material-ui/core/";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 1000,
  },
}));

function ViewFormLayout({
  state,
  select,
  topActions,
  duplicate,
  info,
  table,
  form,
  validationAcronymForm,
  validationRegistryForm,
  recordActions,
  pdfActions,
  pdf,
  collapsablePdf,
  deleteButton,
  sourceSelectionButtons,
  importReview,
  excludeAll,
  exportPublication,
  validatePaperAnnotationsButton,
  updateButton,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          item
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Grid item lg={10}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {select}
            </Box>
          </Grid>
          <Grid item lg={2}>
            <Box
              mr={4}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              {topActions}
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item container lg={10} spacing={2} direction="column">
            <Grid item container spacing={2} direction="row">
              <Grid item container spacing={1} direction="column" lg={3}>
                <Grid item>
                  <Box m={1} ml={5}>
                    {importReview && importReview}
                  </Box>
                </Grid>
                <Grid item>{table}</Grid>
              </Grid>
              <Grid item container spacing={2} direction="column" lg={9}>
                <Grid item>{form}</Grid>
                <Grid item>{validationAcronymForm}</Grid>
                <Grid item>{validationRegistryForm}</Grid>
                <Grid item>{pdf}</Grid>
                <Grid item>{collapsablePdf}</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container lg={2} direction="column" spacing={2}>
            <Grid item>
              {pdfActions && pdfActions}
              {recordActions}
              {deleteButton && deleteButton}
              {sourceSelectionButtons && sourceSelectionButtons}
              {excludeAll && excludeAll}
              {duplicate}
              {exportPublication}
              {validatePaperAnnotationsButton}
              {updateButton}
              {info}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewFormLayout;
