import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { UserConfirmationState } from "../../../../model/annotation/UserConfirmationState";
import utils from "../../../../utils/utils";
import { setConfStateByWorkflowState } from "../../../../utils/validation/confirmationByStateUtils";
import { overWriteCheckboxDisable } from "../../../../utils/validation/ValidationWorkflowStateUtils";
import { buttonColor } from "../../../subcomponents/buttons/buttonProperties";
import ValidationWarningBox from "./ValidationWarningBox";

export const Variables = ({
  publicationData,
  saveVariable,
  currentWorkflowState,
  variables,
  headerName,
}) => {
  return (
    <div>
      <Box display="flex" flexDirection="row">
        {/* <h3 sx={{ mb: 0.5 }}>Chapter 2</h3> */}
        <Typography variant="h5">{headerName}</Typography>
        {/* <Checkbox checked={false} /> */}
      </Box>

      {variables.map((v, idx) => {
        if (publicationData[v] === null) return null;

        return (
          <React.Fragment key={idx + publicationData[v]}>
            <Box mb={1} display="flex">
              <PublicationContentComponentVariable
                content={publicationData[v]}
                saveVariable={saveVariable}
                currentWorkflowState={currentWorkflowState}
              />
            </Box>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const PublicationContentComponentVariable = ({
  content,
  saveVariable,
  currentWorkflowState,
}) => {
  const _checked =
    content.userConfirmationByState.confirmationRELEASE_VALIDATION ===
    UserConfirmationState.ACCEPTED.name;
  const [editedContent, setEditedContent] = React.useState();
  const [lastContent, setLastContent] = React.useState();
  const [disableTextField, setDisableTextField] = React.useState(false);
  const [checked, setChecked] = React.useState(_checked);

  React.useEffect(() => {
    if (content !== null) {
      setEditedContent(utils.jsonCopy(content));
      setLastContent(utils.jsonCopy(content));
    }
    return () => {
      setLastContent();
      setLastContent();
    };
  }, [content]);

  const onSaveVariable = (userConfirmationByState) => {
    let newAcro = editedContent;
    if (
      userConfirmationByState === UserConfirmationState.CHANGED.name &&
      editedContent.content === content.contentOriginal
    ) {
      userConfirmationByState = UserConfirmationState.DEFAULT.name;
    }

    setConfStateByWorkflowState(
      newAcro.userConfirmationByState,
      currentWorkflowState,
      userConfirmationByState
    );
    setEditedContent((editedContent) => {
      return { ...editedContent, ...newAcro };
    });

    setLastContent((lastContent) => {
      return { ...lastContent, ...newAcro };
    });

    saveVariable(editedContent);
  };

  const handleChange = (e) => {
    let newAcro = editedContent;
    newAcro.content = e.target.value;
    setEditedContent((editedContent) => {
      return { ...editedContent, ...newAcro };
    });
  };

  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);
    setDisableTextField(event.target.checked);

    let check;

    if (event.target.checked) {
      check = UserConfirmationState.ACCEPTED.name;
    } else {
      check = UserConfirmationState.DEFAULT.name;
    }

    console.log(check);
    console.log(currentWorkflowState);

    let newAcro = editedContent;
    setConfStateByWorkflowState(
      newAcro.userConfirmationByState,
      currentWorkflowState,
      check
    );
    setEditedContent((editedContent) => {
      return { ...editedContent, ...newAcro };
    });
    // const check = event.target.value ?

    onSaveVariable(check);
  };

  if (!editedContent) return null;

  const textFieldError =
    editedContent.content !== lastContent.content &&
    editedContent.content !== editedContent.contentOriginal;

  const overwriteCheckboxDisable =
    overWriteCheckboxDisable(currentWorkflowState);
  const disableCheckbox =
    editedContent.content !== content.contentOriginal &&
    !overwriteCheckboxDisable;

  // console.log(editedContent.content);

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <Typography
            style={{
              marginRight: 20,
              minWidth: 100,
              // width: 120,
            }}
          >
            {/* prim2.3.4.low95CI.Tend_ABCDEFGZ */}
            {content.variableName ? content.variableName : "No name"}
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          ml={2}
          width={400}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <TextField
            id="annotation"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            margin="dense"
            value={editedContent.content}
            onChange={handleChange}
            error={textFieldError}
            disabled={disableTextField || checked}
          />
        </Box>
        <Box ml={2}>
          <Button
            variant="contained"
            disableRipple
            onClick={() => onSaveVariable(UserConfirmationState.CHANGED.name)}
            color={buttonColor.save}
            disabled={editedContent.content === lastContent.content}
            size="small"
          >
            <Typography variant="body2" align="center">
              Save
            </Typography>
          </Button>
        </Box>
        <Box ml={2}>
          <Typography align="center">Validate</Typography>
        </Box>
        <Checkbox
          checked={checked}
          onChange={handleChangeCheckBox}
          disabled={disableCheckbox}
          // disabled = {content.userConfirmationByState.confirmationRELEASE_VALIDATION}
        />
      </Box>
      <Box>
        {ValidationWarningBox(content.chapter, content.validationWarnings, 2)}
      </Box>
    </React.Fragment>
  );
};
