import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function InfoCard({ record }) {
  return (
    <Card elevation={2} style={{ backgroundColor: "#fafafa", marginTop: 30 }}>
      <CardContent>
        <Typography variant="overline" color="textSecondary">
          Info
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary">
          Generated: {record.filenameGenerated}
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary">
          Uploaded: {record.filenameUploaded ? record.filenameUploaded : "None"}
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary">
          Annotated:{" "}
          {record.filenameUploadedAnnotated
            ? record.filenameUploadedAnnotated
            : "None"}
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary">
          Authors: {record.authorsText}
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary">
          Title: {record.title}
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary">
          DataSource: {record.dataSource}
        </Typography>
        <br />
        {record.duplicate && (
          <Typography variant="caption" color="textSecondary">
            Duplicate of: {record.duplicate}
          </Typography>
        )}
        {record.listDuplicate && duplicates(record)}
      </CardContent>
    </Card>
  );
}

const duplicates = (record) => {
  const duplicates = record.listDuplicate.map((r) => {
    return (
      <div key={r}>
        <Typography variant="caption" color="textSecondary">
          {r}
        </Typography>
        <br />
      </div>
    );
  });

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        Has duplicates:
      </Typography>
      {duplicates}
    </>
  );
};
