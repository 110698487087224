import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 1000,
  },
}));

function ViewTableLayout({ exportButton, select, table }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <br />
      <Grid
        container
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={12}>
          {exportButton}
          {select}
        </Grid>

        <Grid item lg={10}>
          {table}
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewTableLayout;
