import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
/**
 * renders the validation warnings
 */

interface ValidationWarningBoxProps {
  warnings: Array<string>;
  chapter: string;
  accordionMarginLeft: number;
}

export const ValidationWarningBox = ({
  warnings,
  chapter,
  accordionMarginLeft,
}: ValidationWarningBoxProps) => {
  if (warnings === undefined || warnings === null || warnings.length === 0) {
    return null;
  }
  return (
    <div>
      <Box style={{ marginLeft: accordionMarginLeft }}>
        <Box
          sizeWidth={600}
          maxWidth={900}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`validation-warnings-${chapter}`}
              id={`validation-warnings-chapter-${chapter}`}
            >
              <Typography>Warnings occured in this chapter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {warnings.map((warning) => {
                  return (
                    <Grid item xs={12}>
                      <Typography variant="body1" noWrap={false} gutterBottom>
                        {warning}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </div>
  );
};

export default ValidationWarningBox;
