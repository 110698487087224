import { Box, TextField, Typography } from "@material-ui/core";
import React from "react";

export const BasicContentComponent = ({
  content,
  name,
  marginRight,
  minWidth,
  textWidth,
  ml = 2,
  mlName = 0,
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box ml={mlName}>
        <Typography
          style={{
            marginRight: marginRight,
            minWidth: minWidth,
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box
        // flexGrow={1}
        ml={ml}
        width={textWidth}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <TextField
          id="annotation"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          margin="dense"
          value={content}
          disabled={true}
        />
      </Box>
    </Box>
  );
};
