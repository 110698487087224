import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import api, { Action } from "../../../api";
import { getNextState } from "../../../utils/utils";
import { buttonColor } from "../buttons/buttonProperties";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 600,
  },
}));

export default function TableWithActions({
  rows,
  handleForwardBackward,
  state,
  showForward,
}) {
  const classes = useStyles();

  const handleClick = (idx, action) => {
    handleForwardBackward(idx, action);
  };

  let tableItems = createTableItems(rows, handleClick, state, showForward);

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Paper name</TableCell>
            <TableCell align="left">Pubmed ID</TableCell>
            <TableCell align="left">Current state</TableCell>
            <TableCell align="center">
              To {getNextState(state, Action.BACKWARD)}
            </TableCell>
            {showForward && (
              <TableCell align="center">
                To {getNextState(state, Action.FORWARD)}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{tableItems}</TableBody>
      </Table>
    </TableContainer>
  );
}

function createTableItems(rows, handleClick, state, showForward) {
  return rows.map((row, idx) => (
    <TableRow
      key={row.name}
      style={
        (idx + 1) % 2 ? { background: "#f0f5f9" } : { background: "white" }
      }
    >
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell align="left">
        {row.pmid ? row.pmid : "Not available"}
      </TableCell>
      <TableCell align="left">{row.state}</TableCell>

      <TableCell align="center">
        <Button
          variant="contained"
          disableRipple
          // color="default"
          color={buttonColor.backwardTable}
          onClick={() => {
            handleClick(idx, api.Action.BACKWARD);
          }}
          size="small"
          disabled={rows[idx].state !== state}
        >
          Backward
        </Button>
      </TableCell>
      {showForward && (
        <TableCell align="center">
          <Button
            variant="contained"
            disableRipple
            color={buttonColor.forwardTable}
            onClick={() => {
              handleClick(idx, api.Action.FORWARD);
            }}
            size="small"
            disabled={rows[idx].state !== state}
          >
            Forward
          </Button>
        </TableCell>
      )}
    </TableRow>
  ));
}
