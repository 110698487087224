import React, { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import utils, { createSelect } from "../../../utils/utils";
import api from "../../../api";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 140,
  },
}));

// TODO: simplify this

function SelectExclusionStates({
  currentRecord,
  handleExclusionSelection,
  disabled,
}) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //TODO: pre-fetch in init

  useEffect(() => {
    api.fetchExclusionStates().then((data) => {
      let sorted = data;
      utils.sortRecords(sorted, (item) => item.state);

      let idxNotExcluded = sorted.findIndex(
        ({ stateString }) => stateString === "Not excluded"
      );

      let idxOther = sorted.findIndex(
        ({ stateString }) => stateString === "Other"
      );

      const newSorted = [
        sorted[idxNotExcluded],
        ...sorted.filter(
          (item) => !["Not excluded", "Other"].includes(item.stateString)
        ),
        sorted[idxOther],
      ];

      setOptions(newSorted);
    });

    return () => {
      setOptions([]);
    };
  }, []);

  useEffect(() => {
    const idx = options.findIndex(
      ({ state }) => state === currentRecord.exclusionState
    );
    setSelectedIndex(idx);
  }, [currentRecord, options]);

  const handleSelected = (e) => {
    const newExclusionState = options[e.target.value].state;
    const idx = options.findIndex(({ state }) => state === newExclusionState);
    setSelectedIndex(idx);
    handleExclusionSelection(newExclusionState);
  };

  if (selectedIndex >= 0) {
    var SelectStates = createSelect(
      options.map((item) => item.stateString),
      selectedIndex,
      handleSelected,
      "Exclusion States",
      disabled,
      classes
    );
  }

  return (
    <div>
      <Box display="flex" flexDirection="col" justifyContent="right">
        {SelectStates}
      </Box>
    </div>
  );
}

export default SelectExclusionStates;
