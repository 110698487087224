import { Box, Typography } from "@material-ui/core";
import React from "react";
import { ImportReviewButton } from "./buttons/ImportReviewButton";

export const NoAreasFound = ({ showImportReview }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box>
        <Typography variant="h2" color="secondary">
          No areas in this view
        </Typography>
      </Box>
      {showImportReview && (
        <Box mt={5} justifyContent="center">
          <ImportReviewButton />
        </Box>
      )}
    </Box>
  );
};
